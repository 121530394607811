<template>
    <div class="position-relative">
        <LoadingComponentVue
            :isLoading="isLoading"
            style="min-height: 100% !important"
        />
        <div
            v-if="changePhotoModel"
            :class="
                lang == 'en'
                    ? 'change-photo-model-container'
                    : 'change-photo-model-container-ar'
            "
        >
            <LoadingComponentVue :isLoading="isLoadingUpload" />
            <div class="change-photo-model">
                <div
                    style="
                        width: 161px;
                        height: 161px;
                        border-radius: 50%;
                        margin: 15px;
                        overflow: hidden;
                    "
                >
                    <img v-if="fileUrl" :src="fileUrl" alt="profile" />
                    <img v-else src="@/assets/imgs/user-1.png" alt="profile" />
                </div>
                <div>
                    <b-field
                        class="file is-primary"
                        :class="{ 'has-name': !!file }"
                    >
                        <b-upload
                            v-model="file"
                            class="file-label overflow-hidden"
                            accept="image/*"
                        >
                            <span class="file-cta">
                                <b-icon
                                    class="file-icon"
                                    icon="upload"
                                ></b-icon>
                                <span class="file-label">{{
                                    $t("ClickToUpload")
                                }}</span>
                            </span>
                            <span
                                style="
                                    border-bottom-left-radius: 0;
                                    border-top-left-radius: 0;
                                    color: rgb(119, 119, 119);
                                    border: none;
                                    max-width: 50px;
                                "
                                class="file-name"
                                v-if="file"
                            >
                                {{ file.name }}
                            </span>
                        </b-upload>
                    </b-field>
                    <div
                        style="width: 249px"
                        class="text-white d-flex justify-content-around align-items-center gap-1"
                    >
                        <!-- CHANGE_PROFILE_PHOTO -->
                        <button
                            @click="saveProfilePicture()"
                            class="btn btn-success px-2 w-100"
                        >
                            {{ $t("save") }}
                        </button>
                        <button
                            @click="changePhotoModel = false"
                            class="btn btn-danger px-2 w-100"
                        >
                            {{ $t("cancel") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ul :style="lang == 'en' ? 'direction:ltr' : 'direction:rtl'">
            <!-- ========= Hello, Name ========= -->
            <li v-if="lang == 'en'">
                <h5 class="user-menu-component-title">
                    {{ $t("sayHi") }} {{ userData.name }}
                </h5>
            </li>
            <li v-else>
                <h5 class="user-menu-component-title">
                    {{ $t("sayHi") }} {{ userData.name_ar }}
                </h5>
            </li>
            <li>
                <p class="text-center text-info text-decoration-underline">
                    {{ userData.role }}
                </p>
            </li>
            <li>
                <a
                    title="go to Email page"
                    style="color: rgb(121, 121, 231); cursor: pointer"
                    class="text-decoration-underline"
                    target="_blank"
                    href="/webmail"
                >
                    <p class="mb-1">
                        {{ userData.email }}
                    </p>
                </a>
            </li>
            <li>
                <p class="text-muted mb-1">
                    {{ $t("userId") }} :{{ userData.mobile_number }}
                </p>
            </li>
            <li>
                <p class="text-muted mb-1" v-if="userData.position">
                    {{ $t("position") }} :{{ userData.position }}
                </p>
                <p class="text-muted mb-1" v-else>
                    {{ $t("position") }} :
                    <span class="text-dark">
                        {{ userData.role }}
                    </span>
                </p>
            </li>
            <li>
                <p class="text-muted mb-1">
                    {{ $t("createdAt") }} :{{ formattedDate }}
                </p>
            </li>
            <!-- ========= change Profile Photo ========= -->
            <li @click="OpenChangeProfilePicture()">
                <div
                    class="user-menu-change-photo center-flex gap-1 mt-5 btn btn-warning w-100 text-white"
                >
                    <span>{{ $t("changeYourPhoto") }}</span>
                    <span class="text-warning"
                        ><i class="fa-solid fa-camera-retro"></i
                    ></span>
                </div>
            </li>
        </ul>

        <!-- ========= Errors Msg ========= -->
        <span v-if="errorMsg" class="text-danger text-center"
            >{{ errorMsg }} !</span
        >
    </div>
</template>
<script>
import LoadingComponentVue from "../animations/LoadingComponent";
import axios from "axios";
import { BASE_URL } from "@/utils/constants";
import { USER_PROFILE, CHANGE_PROFILE_PHOTO } from "@/utils/api";
import store from "@/store";

import { format } from "date-fns";
export default {
    name: "UserMenu",
    components: { LoadingComponentVue },

    data() {
        return {
            isLoading: true,
            isLoadingUpload: false,
            userData: {},
            lang: localStorage.getItem("lang"),
            errorMsg: "",
            changePhotoModel: false,
            fromData: [],
            file: null,
            fileUrl: null,
        };
    },
    computed: {
        formattedDate: {
            get() {
                if (!this.userData.created_at) {
                    return null;
                }
                // Parse the date string from userData using Date object
                const date = new Date(this.userData.created_at);
                // Format the date using toLocaleDateString
                return date.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                });
            },
            set(newValue) {
                // Format the Date object as a string and update userData
                this.$set(
                    this.userData,
                    "created_at",
                    format(newValue, "yyyy-MM-dd")
                );
            },
        },
    },

    async mounted() {
        await this.getUserProfile();
    },

    methods: {
        // ==========
        success(message) {
            this.$buefy.toast.open({
                duration: 3000,
                message: message,
                type: "is-success",
            });
        },
        danger(error) {
            this.$buefy.toast.open({
                duration: 3000,
                // message: `<b style="font-size: 1rem; min-width: 400px;"><h5 style="">${this.errorMsg}</h5></b>`,
                message: `<b style="font-size: 1rem; min-width: 400px;"><h5 style="">${error}</h5></b>`,
                position: "is-bottom",
                type: "is-danger",
            });
        },

        closeIndefinite() {
            if (this.indefinteToast) {
                this.indefinteToast.close();
                this.indefinteToast = null;
            }
        },
        // ==========
        async saveProfilePicture() {
            this.isLoadingUpload = true;
            try {
                const formData = new FormData();
                formData.append("photo", this.file);
                const response = await axios.patch(
                    `${BASE_URL}${CHANGE_PROFILE_PHOTO}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    // console.log(response);
                    this.isLoadingUpload = false;
                    this.changePhotoModel = false;
                    this.success(response.data.message);
                    this.getUserProfile();
                    window.location.reload();
                }
            } catch (error) {
                if (error) {
                    console.error("Error:", error);
                }
                // Handle errors, e.g., update UI to indicate failure
            } finally {
                this.isLoadingUpload = false;
            }
        },
        async getUserProfile() {
            this.isLoading = true;
            try {
                const userToken = store.getters.getUserToken;
                const userId = store.getters.getUser.user_id;
                const response = await axios.get(`${BASE_URL}${USER_PROFILE}`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                    params: { user_id: userId },
                });
                if (response.status === 200) {
                    this.userData = response.data;
                    this.isLoading = false;
                } else {
                    this.errorMsg = response.data.detail;
                    this.userData = response.data;
                }
            } catch (error) {
                if (error) {
                    // this.errorMsg = error.response.data.detail;
                    console.log(error.response);
                    this.isLoading = false;
                }
                setTimeout(() => {
                    this.errorMsg = "";
                }, 5000);
            }
        },
        OpenChangeProfilePicture() {
            this.changePhotoModel = true;
            this.$store.commit("setChangePhotoModel", true);
        },
    },
    watch: {
        file(newFile) {
            if (newFile) {
                // Create a temporary URL for image preview
                this.fileUrl = URL.createObjectURL(newFile);
            } else {
                // If no file is selected, reset the URL
                this.fileUrl = null;
            }
        },
    },
};
</script>
<style lang="scss">
.user-menu-component-title {
    text-align: center;
    border-bottom: 1px solid darkgray;
    padding: 2px 0;
}
.user-menu-change-photo {
    &:hover {
        cursor: pointer;
        color: black;
    }
}
// change photo model
.change-photo-model-container {
    position: fixed;
    top: -62px;
    right: -29px;
    z-index: 9999999999;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 67%);
    backdrop-filter: blur(7px);
    display: flex;
    // align-items: center;
    justify-content: center;
}
.change-photo-model-container-ar {
    position: fixed;
    top: -62px;
    left: -29px;
    z-index: 9999999999;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 67%);
    backdrop-filter: blur(7px);
    display: flex;
    // align-items: center;
    justify-content: center;
}
.change-photo-model {
    background-color: white;
    background-color: #2c2c2c;
    width: 34%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    border-radius: 16px;
    padding: 20px;
}
</style>
