<template>
    <div
        v-on-clickaway="closeTabClickaway"
        class="app-notification-page-container"
    >
        <!-- delete modal Start  -->
        <div
            v-if="deleteConfirm"
            style="top: 25vh; right: 25vw; z-index: 99999"
            class="deleteModal bg-warning center-flex flex-column gap-3 w-50 mx-auto p-5 rounded-2 position-fixed border border-3 border-secondary"
        >
            <h2 class="text-white bg-warning">
                <i
                    class="fa-solid fa-triangle-exclamation fa-beat-fade mx-2"
                ></i>
                {{ $t("cheques.changeChequeStatus") }} !
            </h2>
            <div
                class="deleteModal bg-warning center-flex gap-3 w-100 mx-auto p-5 rounded-2"
            >
                <button
                    v-if="showPropertyNots"
                    @click="confirmDeleteNow()"
                    class="btn btn-success w-25"
                >
                    {{ $t("cheques.ChequeStatusIsPaid") }}
                </button>
                <button
                    v-if="showTenantNots"
                    @click="confirmDeleteNowTenant()"
                    class="btn btn-success w-25"
                >
                    {{ $t("cheques.ChequeStatusIsPaid") }}
                </button>
                <button
                    @click="deleteConfirm = false"
                    class="btn btn-danger w-25"
                >
                    {{ $t("cancel") }}
                </button>
            </div>
        </div>
        <!-- delete modal End  -->
        <LoadingComponent :isLoading="isLoading" />
        <h3 class="text-center text-white p-2">
            <div class="center-flex">
                <span
                    v-if="tenantNots && propertyNots"
                    class="fs-6 rounded-circle bg-danger me-2 text-center"
                    style="width: 20px; height: 20px"
                >
                    {{ tenantNots.length + propertyNots.length }}
                </span>
            </div>
            {{ $t("appNotifications.title") }}
            <i
                @click="checkCheques()"
                title="تحديث التنبيهات"
                class="fa-solid fa-arrows-rotate mx-3"
                style="cursor: pointer"
                :class="isLoading ? 'fa-spin text-warning' : ''"
            ></i>
        </h3>

        <!-- tabs Start -->
        <div class="notification-tab-container">
            <div
                @click="
                    showPropertyNots = true;
                    showTenantNots = false;
                "
                class="notification-tab"
                :class="showPropertyNots ? 'notifications-active' : ''"
            >
                <div class="center-flex">
                    <span
                        v-if="propertyNots"
                        class="fs-6 rounded-circle bg-danger me-2 text-center"
                        style="width: 20px; height: 20px"
                    >
                        {{ propertyNots.length }}
                    </span>
                    <span> {{ $t("appNotifications.notsProperty") }} </span>
                </div>
            </div>
            <div
                @click="
                    showPropertyNots = false;
                    showTenantNots = true;
                "
                class="notification-tab"
                :class="showPropertyNots ? '' : 'notifications-active'"
            >
                <div class="center-flex">
                    <span
                        v-if="tenantNots"
                        class="fs-6 rounded-circle bg-danger me-2 text-center"
                        style="width: 20px; height: 20px"
                    >
                        {{ tenantNots.length }}
                    </span>
                    <span> {{ $t("appNotifications.notsTenant") }} </span>
                </div>
            </div>
        </div>
        <!-- tabs End -->

        <div class="notification-container">
            <!-- property Notifications Start -->
            <div v-show="propertyNots.length && showPropertyNots">
                <!-- <div class="w-25 fs-5 text-center text-white p-1">
                    {{ propertyNots.length }}
                </div> -->
                <div
                    v-for="notification in propertyNots"
                    :key="notification.nots_id"
                >
                    <div
                        style="cursor: pointer; padding-bottom: 0px"
                        class="m-2 px-3 pt-5 pb-3 text-start text-secondary bg-light rounded-2 shadow"
                    >
                        <p
                            @click="
                                showPartitionModal = false;
                                showRelatedProperty(notification.property);
                            "
                            class=""
                        >
                            {{ notification.message }}
                        </p>
                        <div class="mx-auto w-75">
                            <button
                                @click="
                                    openDeleteRevenue(
                                        (deletedId = notification.cheque),
                                        (notsId = notification.nots_id)
                                    )
                                "
                                class="btn btn-success w-100 mt-2 mx-auto mt-5"
                            >
                                {{ $t("appNotifications.isPaid") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- property Notifications End -->

            <!-- Tenant Notifications Start -->
            <div v-show="tenantNots.length && showTenantNots">
                <div
                    v-for="notification in tenantNots"
                    :key="notification.nots_id"
                >
                    <div
                        style="cursor: pointer"
                        class="m-2 px-3 pt-5 pb-3 text-start text-secondary bg-light rounded-2 shadow"
                    >
                        <p @click="showMoreDetails(notification.partition)">
                            {{ notification.message }}
                        </p>
                        <div class="mx-auto w-75">
                            <button
                                @click="
                                    openDeleteRevenueTenant(
                                        (deletedIdTenant = notification.cheque),
                                        (notsIdTenant = notification.nots_id)
                                    )
                                "
                                class="btn btn-success w-100 mt-2 mx-auto mt-5"
                            >
                                {{ $t("appNotifications.isCollected") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tenant Notifications End -->
        </div>
        <button
            @click="closeNotifications()"
            class="btn btn-danger close-notification-btn w-100"
        >
            X
        </button>

        <!--  show Related Property -->
        <div v-show="showRelatedPropertyModal">
            <button
                @click="showRelatedPropertyModal = false"
                class="btn btn-danger position-fixed"
                style="
                    top: 35px;
                    left: 10px;
                    z-index: 999999;
                    font-size: 9px;
                    width: 30px;
                    height: 30px;
                "
            >
                X
            </button>
            <b-modal
                class=""
                v-model="showRelatedPropertyModal"
                :width="640"
                scroll="keep"
            >
                <div class="card px-2">
                    <div class="card-content">
                        <div
                            style="border-bottom: 2px solid gray"
                            class="media justify-content-evenly"
                        >
                            <div class="media-left">
                                <span>
                                    <span
                                        class="rounded-circle d-inline-block overflow-hidden"
                                        ><img
                                            width="70px"
                                            src="@/assets/imgs/svg-icons/apartment.svg"
                                            alt=""
                                    /></span>
                                </span>
                                <span class="title is-4">
                                    {{ relatedProperty.partition_number }}
                                    {{ relatedProperty.property_number }}
                                </span>
                                <div class="media-right">
                                    <span
                                        v-if="
                                            relatedProperty &&
                                            relatedProperty.landlord &&
                                            relatedProperty.landlord.name
                                        "
                                    >
                                        <span v-if="lang === 'en'">
                                            {{ relatedProperty.landlord.name }}
                                        </span>
                                        <span v-else>
                                            {{
                                                relatedProperty.landlord.name_ar
                                            }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <small
                                    >{{ $t("property.dateOfRentBeginning") }} :
                                    {{
                                        relatedProperty.date_of_rent_beginning
                                    }}</small
                                >
                                <small
                                    >{{ $t("property.dateOfRentEnd") }} :
                                    {{
                                        relatedProperty.date_of_rent_end
                                    }}</small
                                >
                            </div>
                        </div>
                        <!--= Landlord = -->
                        <div class="card-content pt-1">
                            <h3 class="text-black">{{ $t("landlord") }} :</h3>
                            <p>
                                <span
                                    v-if="
                                        relatedProperty &&
                                        relatedProperty.landlord &&
                                        relatedProperty.landlord.name
                                    "
                                >
                                    <span v-if="lang === 'en'">
                                        {{ relatedProperty.landlord.name }}
                                    </span>
                                    <span v-else>
                                        {{ relatedProperty.landlord.name_ar }}
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span> {{ $t("property.rentAmount") }} : </span>
                                {{ relatedProperty.rent_amount }}

                                {{ relatedProperty.rent_amount }}
                            </p>
                        </div>
                        <!-- === Broker === -->
                        <div class="card-content pt-1">
                            <h3 class="text-black">{{ $t("broker") }} :</h3>
                            <p>
                                <span
                                    v-if="
                                        relatedProperty &&
                                        relatedProperty.broker &&
                                        relatedProperty.broker.name
                                    "
                                >
                                    <span v-if="lang === 'en'">
                                        {{ relatedProperty.broker.name }}
                                    </span>
                                    <span v-else>
                                        {{ relatedProperty.broker.name_ar }}
                                    </span>
                                </span>
                                <span v-else>لا يوجد</span>
                            </p>
                            <p>
                                <span>
                                    {{ $t("property.brokerCommission") }} :
                                </span>
                                {{ relatedProperty.broker_commission }}
                            </p>
                            <p>
                                <span>
                                    {{ $t("property.floorNumber") }} :
                                </span>
                                {{ relatedProperty.floor }}
                            </p>
                            <p>
                                <span>{{ $t("property.address") }} : </span>
                                {{ relatedProperty.address }}
                            </p>
                        </div>
                        <div class="card-content pt-1">
                            <h4 class="text-black">{{ $t("address") }} :</h4>
                            <!-- location -->
                            <p>
                                <span class="fs-6">
                                    {{ $t("property.location") }}:
                                </span>
                                <span>
                                    <a
                                        target="_blank"
                                        :href="relatedProperty.location"
                                    >
                                        <i
                                            v-show="relatedProperty.location"
                                            class="fa-solid fa-location-dot fa-bounce mx-2"
                                        ></i>
                                        {{ relatedProperty.location }}
                                    </a>
                                </span>
                            </p>
                            <p>
                                <span>
                                    {{ $t("property.buildingNumber") }} :
                                </span>
                                {{ relatedProperty.building_number }}
                            </p>
                            <p>
                                <span>
                                    {{ $t("property.floorNumber") }} :
                                </span>
                                {{ relatedProperty.floor }}
                            </p>
                            <p>
                                <span>{{ $t("property.address") }} : </span>
                                {{ relatedProperty.address }}
                            </p>
                        </div>

                        <!-- property.components -->
                        <div class="card-content pt-1">
                            <h4 class="text-black">
                                {{ $t("property.components") }} :
                            </h4>

                            <!-- number_of_rooms -->
                            <p>
                                <span>
                                    {{ $t("property.numberOfRooms") }}:
                                </span>
                                <span>
                                    {{ relatedProperty.number_of_rooms }}
                                </span>
                            </p>

                            <!-- number_of_bathrooms -->
                            <p>
                                <span>
                                    {{ $t("property.numberOfBathrooms") }}:
                                </span>
                                <span>
                                    {{ relatedProperty.number_of_bathrooms }}
                                </span>
                            </p>
                            <!-- number_of_balconies -->
                            <p>
                                <span>
                                    {{ $t("property.numberOfBalconies") }}:
                                </span>
                                <span>
                                    {{ relatedProperty.number_of_balconies }}
                                </span>
                            </p>
                        </div>
                        <!-- <h3>{{ $t("property.location") }}</h3>
                        <p>{{ propertyDetails.location }}</p> -->
                        <!-- ===== Description -->

                        <div class="card-content pt-1">
                            <h4 class="text-black">
                                {{ $t("property.description") }} :
                            </h4>
                            <!-- area_squared -->
                            <p>
                                <span> {{ $t("property.areaSquared") }}: </span>
                                <span>
                                    {{ propertyDetails.area_squared }}
                                    {{ $t("property.squared") }}
                                </span>
                            </p>
                            <!-- area_squared -->
                            <p>
                                <span> {{ $t("property.description") }}: </span>
                                <span>
                                    {{ propertyDetails.description }}
                                    {{ $t("property.squared") }}
                                </span>
                            </p>
                            <!-- luxury_level -->
                            <p>
                                <span> {{ $t("property.luxuryLevel") }}: </span>
                                <span>
                                    {{ propertyDetails.luxury_level }}
                                </span>
                            </p>

                            <!-- property_type -->
                            <p>
                                <span>
                                    {{ $t("property.propertyType") }}:
                                </span>
                                <span>
                                    {{ propertyDetails.property_type }}
                                </span>
                            </p>

                            <!-- hasElevator -->
                            <p>
                                <span
                                    class="text-success"
                                    v-if="(propertyDetails.has_elevator = true)"
                                >
                                    <i class="fa-solid fa-elevator"></i>
                                    {{ $t("property.hasElevator") }}
                                </span>
                                <span v-else class="text-danger">
                                    {{ $t("property.noElevator") }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>

        <!--  show Partition Details -->
        <div>
            <div>
                <button
                    v-show="showPartitionModal"
                    @click="showPartitionModal = false"
                    class="btn btn-danger position-fixed"
                    style="
                        top: 35px;
                        left: 10px;
                        z-index: 999999;
                        font-size: 9px;
                        width: 30px;
                        height: 30px;
                    "
                >
                    X
                </button>
                <b-modal
                    class=""
                    v-model="showPartitionModal"
                    :width="640"
                    scroll="keep"
                >
                    <div class="card px-2">
                        <div class="card-content">
                            <div
                                style="border-bottom: 2px solid gray"
                                class="media justify-content-evenly"
                            >
                                <div class="media-left">
                                    <img
                                        width="30px"
                                        class="mb-2"
                                        src="@/assets/imgs/svg-icons/bedroom-partition.svg"
                                        alt=""
                                    />
                                    <span class="title is-4">
                                        {{ partitionDetails.partition_number }}
                                    </span>
                                    <div class="media-right">
                                        <span
                                            v-if="
                                                partitionDetails &&
                                                partitionDetails.landlord &&
                                                partitionDetails.landlord.name
                                            "
                                        >
                                            <span v-if="lang === 'en'">
                                                {{
                                                    partitionDetails.landlord
                                                        .name
                                                }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    partitionDetails.landlord
                                                        .name_ar
                                                }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <small
                                        v-show="
                                            partitionDetails.is_occupied == true
                                        "
                                        >{{
                                            $t("property.dateOfRentBeginning")
                                        }}
                                        :
                                        {{
                                            partitionDetails.date_of_rent_beginning
                                        }}</small
                                    >
                                    <small
                                        v-show="
                                            partitionDetails.is_occupied == true
                                        "
                                        >{{ $t("property.dateOfRentEnd") }} :
                                        {{
                                            partitionDetails.date_of_rent_end
                                        }}</small
                                    >
                                </div>
                                <div class="d-flex flex-column">
                                    <span
                                        v-if="
                                            partitionDetails.is_occupied == true
                                        "
                                        class="bg-success text-white p-2 rounded-2 text-decoration-underline"
                                        >{{
                                            $t("partition.isOccupiedTrue")
                                        }}</span
                                    >
                                    <span
                                        v-else
                                        class="bg-danger text-white p-2 rounded-2"
                                        >{{
                                            $t("partition.isOccupiedFalse")
                                        }}</span
                                    >
                                </div>
                            </div>
                            <!--= Broker = -->

                            <!-- ====== -->
                            <div
                                v-show="partitionDetails.is_occupied"
                                class="card-content pt-1"
                            >
                                <h3 class="text-black">{{ $t("broker") }} :</h3>
                                <p>
                                    <span
                                        v-if="
                                            partitionDetails &&
                                            partitionDetails.broker &&
                                            partitionDetails.broker.name
                                        "
                                    >
                                        <span v-if="lang === 'en'">
                                            {{ partitionDetails.broker.name }}
                                        </span>
                                        <span v-else>
                                            {{
                                                partitionDetails.broker.name_ar
                                            }}
                                        </span>
                                    </span>
                                    <span v-else>لا يوجد</span>
                                </p>
                                <p>
                                    <span>
                                        {{ $t("property.brokerCommission") }} :
                                    </span>
                                    {{ partitionDetails.broker_commission }}
                                </p>
                            </div>

                            <!--= tenant = -->

                            <!-- ====== -->
                            <!-- <div
                                v-show="partitionDetails.is_occupied"
                                class="card-content pt-1"
                            >
                                <h3 class="text-black">
                                    {{ $t("partition.tenant") }} :
                                </h3>
                                <p>
                                    <span
                                        v-if="
                                            partitionDetails &&
                                            partitionDetails.tenant &&
                                            partitionDetails.tenant.name
                                        "
                                    >
                                        <span v-if="lang === 'en'">
                                            {{ partitionDetails.tenant.name }}
                                        </span>
                                        <span v-else>
                                            {{
                                                partitionDetails.tenant.name_ar
                                            }}
                                        </span>
                                    </span>
                                    <span v-else>لا يوجد</span>
                                </p>
                            </div> -->
                            <!-- =================== -->
                            <div class="card-content pt-1">
                                <h4 class="text-black">
                                    {{ $t("partition.rentAmount") }} :
                                </h4>
                                <!-- location -->
                                <p>
                                    <span class="fs-6">
                                        {{ $t("property.rentAmount") }}:
                                    </span>
                                    <span>
                                        {{ partitionDetails.rent_amount }}
                                    </span>
                                </p>
                            </div>
                            <div class="card-content pt-1">
                                <h4 class="text-black">
                                    {{ $t("address") }} :
                                </h4>
                                <!-- location -->
                                <p>
                                    <span class="fs-6">
                                        {{ $t("property.location") }}:
                                    </span>
                                    <span>
                                        <a
                                            target="_blank"
                                            :href="partitionDetails.location"
                                        >
                                            <i
                                                v-show="
                                                    partitionDetails.location
                                                "
                                                class="fa-solid fa-location-dot fa-bounce mx-2"
                                            ></i>
                                            {{ partitionDetails.location }}
                                        </a>
                                    </span>
                                </p>
                                <p>
                                    <button
                                        @click="
                                            showPartitionModal = false;
                                            showRelatedProperty(
                                                partitionDetails.property
                                            );
                                        "
                                        class="btn btn-success mt-2 w-50"
                                    >
                                        <span> بيانات الشقة التابع لها </span>
                                    </button>
                                </p>
                            </div>

                            <!-- <h3>{{ $t("property.location") }}</h3>
                        <p>{{ partitionDetails.location }}</p> -->
                            <!-- ===== Description -->

                            <div class="card-content pt-1">
                                <h4 class="text-black">
                                    {{ $t("property.description") }} :
                                </h4>
                                <!-- title -->
                                <p>
                                    <span> {{ $t("partition.title") }}: </span>
                                    <span>
                                        {{ partitionDetails.title }}
                                    </span>
                                </p>
                                <!-- area_squared -->
                                <p>
                                    <span>
                                        {{ $t("property.areaSquared") }}:
                                    </span>
                                    <span>
                                        {{ partitionDetails.area_squared }}
                                        {{ $t("property.squared") }}
                                    </span>
                                </p>
                                <!-- area_squared -->
                                <p>
                                    <span>
                                        {{ $t("property.description") }}:
                                    </span>
                                    <span>
                                        {{ partitionDetails.description }}
                                    </span>
                                </p>
                                <!-- luxury_level -->
                                <p>
                                    <span>
                                        {{ $t("property.luxuryLevel") }}:
                                    </span>
                                    <span>
                                        {{ partitionDetails.luxury_level }}
                                    </span>
                                </p>

                                <!-- has bathroom -->
                                <p
                                    :class="
                                        partitionDetails.has_bathroom
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                >
                                    <span>
                                        <i class="fa-solid fa-toilet"></i> :
                                    </span>
                                    <span
                                        v-show="
                                            partitionDetails.has_bathroom ==
                                            true
                                        "
                                    >
                                        {{ $t("partition.hasBathroom") }}
                                    </span>
                                    <span
                                        v-show="
                                            partitionDetails.has_bathroom ==
                                            false
                                        "
                                    >
                                        {{ $t("partition.hasNoBathroom") }}
                                    </span>
                                </p>
                                <!-- has balcony -->
                                <p
                                    :class="
                                        partitionDetails.has_balcony
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                >
                                    <span>
                                        <i
                                            class="fa-solid fa-person-through-window"
                                        ></i>
                                        :
                                    </span>
                                    <span
                                        v-show="
                                            partitionDetails.has_balcony == true
                                        "
                                    >
                                        {{ $t("partition.hasBalcony") }}
                                    </span>
                                    <span
                                        v-show="
                                            partitionDetails.has_balcony ==
                                            false
                                        "
                                    >
                                        {{ $t("partition.hasNoBalcony") }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingComponent from "@/components/animations/LoadingComponent.vue";
import { directive as onClickaway } from "vue-clickaway";
import axios from "axios";
import {
    NOTIFICATIONS_PROPERTY_LIST,
    CHECK_CHEQUES,
    NOTIFICATIONS_TENANT_LIST,
    PARTITION_RETRIEVE,
    PROPERTY_RETRIEVE,
    TENANT_CHEQUE_CHANGE_STATUS,
    PROPERTY_CHEQUE_CHANGE_STATUS,
    DELETE_CHEQUE_NOTIFICATION,
    DELETE_TENANT_CHEQUE_NOTIFICATION,
} from "@/utils/api";
import { BASE_URL } from "@/utils/constants";
export default {
    name: "AppNotifications",
    components: {
        LoadingComponent,
    },
    directives: {
        onClickaway: onClickaway,
    },
    data() {
        return {
            deletedId: "",
            deletedIdTenant: "",
            lang: localStorage.getItem("lang"),
            isLoading: false,
            propertyNots: JSON.parse(localStorage.getItem("propertyNots")) || 0,
            tenantNots: JSON.parse(localStorage.getItem("tenantNots")) || 0,

            showPropertyNots: true,
            showTenantNots: false,

            showRelatedPropertyModal: false,
            relatedProperty: {},
            partitionDetails: {},
            propertyDetails: {},

            // partition
            showPartitionModal: false,
            deleteConfirm: false,
            formData: {
                is_paid: true,
            },
            formDataTenant: {
                is_collected: true,
            },
            nodsId: "",
            nodsIdTenant: "",
        };
    },

    async mounted() {
        // await this.checkCheques();
        // await this.getNotifications();
        // await this.getNotificationsTenant();
    },

    methods: {
        //#region // essential methods
        success(message) {
            this.$buefy.toast.open({
                duration: 3000,
                message: message,
                type: "is-success",
            });
        },
        danger(error) {
            this.$buefy.toast.open({
                duration: 3000,
                // message: `<b style="font-size: 1rem; min-width: 400px;"><h5 style="">${this.errorMsg}</h5></b>`,
                message: `<b style="font-size: 1rem; min-width: 400px;"><h5 style="">${error}</h5></b>`,
                position: "is-bottom",
                type: "is-danger",
            });
        },

        closeIndefinite() {
            if (this.indefinteToast) {
                this.indefinteToast.close();
                this.indefinteToast = null;
            }
        },
        getIndex(index) {
            return index + 1;
        },
        //#endregion // essential methods
        closeTabClickaway() {
            // console.log(this.showContactsChild, "this.showContactsChild");
            this.closeNotifications();
        },
        closeNotifications() {
            this.$emit("closeNotifications");
        },
        async getNotifications() {
            try {
                const response = await axios.get(
                    `${BASE_URL}${NOTIFICATIONS_PROPERTY_LIST}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    this.propertyNots = response.data;
                    localStorage.setItem(
                        "propertyNots",
                        JSON.stringify(this.propertyNots)
                    );
                    if (this.propertyNots == []) {
                        localStorage.setItem("propertyNots", []);
                    }
                    // console.log(response);
                }
                // this.success(response.data.message);
                // this.getNotifications();
            } catch (error) {
                if (error) {
                    this.isLoading = false;
                    console.log(error.response);
                }
            }
        },
        async getNotificationsTenant() {
            try {
                const response = await axios.get(
                    `${BASE_URL}${NOTIFICATIONS_TENANT_LIST}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    this.tenantNots = response.data;
                    localStorage.setItem(
                        "tenantNots",
                        JSON.stringify(this.tenantNots)
                    );
                    if (this.tenantNots == []) {
                        localStorage.setItem("tenantNots", []);
                    }
                    // console.log(response);
                }
                // this.success(response.data.message);
                // this.getNotifications();
            } catch (error) {
                if (error) {
                    this.isLoading = false;
                    console.log(error.response);
                }
            }
        },
        // check cheques
        async checkCheques() {
            this.isLoading = true;
            try {
                const response = await axios.get(
                    `${BASE_URL}${CHECK_CHEQUES}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    // console.log(response);
                    this.getNotifications();
                    this.getNotificationsTenant();
                }
            } catch (error) {
                if (error) {
                    this.isLoading = false;
                    console.log(error.response);
                }
            }
        },
        // show Related Property Start
        async showRelatedProperty(id) {
            this.isLoading = true;
            // console.log("Related Property", id);
            try {
                const userToken = this.$store.getters.getUserToken;
                const response = await axios.get(
                    `${BASE_URL}${PROPERTY_RETRIEVE}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                        params: { property_id: id },
                    }
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    this.showRelatedPropertyModal = true;
                    // console.log("PartitionDetails :", response);
                    this.relatedProperty = response.data;
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        },
        // show Related Property End

        async showMoreDetails(id) {
            // console.log("partition id", id);
            await this.getIdAndData(id);
            this.showPartitionModal = true;
        },
        async getIdAndData(id) {
            try {
                const userToken = this.$store.getters.getUserToken;
                const response = await axios.get(
                    `${BASE_URL}${PARTITION_RETRIEVE}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                        params: { partition_id: id },
                    }
                );
                if (response.status === 200) {
                    // this.showPartitionModal = true;
                    // this.openPropertyModal(id);
                    this.partitionDetails = response.data;
                    // console.log("PartitionDetails :", this.partitionDetails);
                    this.partitionId = id;
                    // console.log(
                    //     "set id and data : ",
                    //     id,
                    //     this.partitionDetails
                    // );
                }
            } catch (error) {
                console.log(error);
            }
        },

        // Delete  = change cheque Status Start
        openDeleteRevenue(id, notsId) {
            // console.log("delete expenses", id);
            // console.log("nodsId", notsId);
            this.deleteConfirm = true;
            this.nodsId = notsId;
        },
        openDeleteRevenueTenant(id, notsId) {
            // console.log("delete expenses", id);
            // console.log("nodsId", notsId);
            this.deleteConfirm = true;
            this.nodsIdTenant = notsId;
        },
        confirmDeleteNow() {
            // console.log("confirm deleteNow", this.deletedId);
            this.sendDeleteFormData();
        },
        confirmDeleteNowTenant() {
            // console.log("confirm deleteTenantNow", this.deletedIdTenant);
            this.sendDeleteFormDataTenant();
        },
        async sendDeleteFormData() {
            this.isLoading = true;
            try {
                const response = await axios.patch(
                    `${BASE_URL}${PROPERTY_CHEQUE_CHANGE_STATUS}`,
                    this.formData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                        params: {
                            cheque_id: `${this.deletedId}`,
                        },
                    }
                );
                if (
                    response.status === 200 ||
                    response.status === 201 ||
                    response.status === 204
                ) {
                    this.successMsg = response.data.message;
                    this.isLoading = false;
                    // this.success(response.data.message);
                    this.success(response.data.message);
                    this.showKeepToAdd = true;
                    this.deleteConfirm = false;
                    await this.deletePropertyNotification();
                    this.checkCheques();
                }
            } catch (error) {
                if (error.response) {
                    this.isLoading = false;
                    this.danger(error.response.data.detail);
                    this.deleteConfirm = false;
                } else {
                    this.errorMsg =
                        "An error occurred. Please contact the administrator.";
                    this.isLoading = false;
                    this.deleteConfirm = false;
                }
            }
        },
        async sendDeleteFormDataTenant() {
            this.isLoading = true;
            try {
                const response = await axios.patch(
                    `${BASE_URL}${TENANT_CHEQUE_CHANGE_STATUS}`,

                    this.formDataTenant,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                        params: {
                            cheque_id: `${this.deletedIdTenant}`,
                        },
                    }
                );
                if (
                    response.status === 200 ||
                    response.status === 201 ||
                    response.status === 204
                ) {
                    this.successMsg = response.data.message;
                    this.isLoading = false;
                    // this.success(response.data.message);
                    this.success(response.data.message);
                    this.showKeepToAdd = true;
                    this.deleteConfirm = false;
                    await this.deleteTenantNotification();
                    this.checkCheques();
                }
            } catch (error) {
                if (error.response) {
                    this.isLoading = false;
                    this.danger(error.response.data.detail);
                    this.deleteConfirm = false;
                } else {
                    this.errorMsg =
                        "An error occurred. Please contact the administrator.";
                    this.isLoading = false;
                    this.deleteConfirm = false;
                }
            }
        },
        async deletePropertyNotification() {
            // DELETE_CHEQUE_NOTIFICATION
            try {
                const response = await axios.delete(
                    `${BASE_URL}${DELETE_CHEQUE_NOTIFICATION}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                        params: {
                            nots_id: `${this.nodsId}`,
                        },
                    }
                );
                if (response.status == 204) {
                    // console.log(response);
                }
            } catch (error) {
                if (error) {
                    console.log(error);
                }
            }
        },
        async deleteTenantNotification() {
            // DELETE_CHEQUE_NOTIFICATION
            try {
                const response = await axios.delete(
                    `${BASE_URL}${DELETE_TENANT_CHEQUE_NOTIFICATION}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                        params: {
                            nots_id: `${this.nodsIdTenant}`,
                        },
                    }
                );
                if (response.status == 204) {
                    // console.log(response);
                }
            } catch (error) {
                if (error) {
                    console.log(error);
                }
            }
        },
        // Delete  = change cheque Status End
    },
};
</script>

<style lang="scss">
.app-notification-page-container {
    overflow: auto;
    min-height: var(--min-height-page);
}
.notification-container {
    min-height: 76.5vh;
}
// d-flex justify-content-around align-items-center shadow bg-dark p-4
.notification-tab-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    border-bottom: solid 2px white;
}
.notification-tab {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: white;
    font-size: 1.2rem;
    padding: 10px;
    border: solid 2px white;
    border-bottom: none;
    width: 49%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    min-height: 62px;
    background: rgb(97, 97, 97);
    &:hover {
        background: goldenrod;
    }
}
.notifications-active {
    background-color: rgb(15, 148, 95);
}
</style>
