import axios from "axios";
import jwtDecode from "jwt-decode";
import { BASE_URL } from "@/utils/constants";
import { LOGIN } from "@/utils/api";

function getFromLocalStorage(key, defaultValue = null) {
    const item = localStorage.getItem(key);
    try {
        return JSON.parse(item) || defaultValue;
    } catch (error) {
        return defaultValue;
    }
}
function getFromSessionStorage(key, defaultValue = null) {
    const item = sessionStorage.getItem(key);
    try {
        return JSON.parse(item) || defaultValue;
    } catch (error) {
        return defaultValue;
    }
}

const state = {
    user:
        getFromSessionStorage("userInfo") ||
        getFromLocalStorage("userInfo") ||
        null,

    userId:
        getFromSessionStorage("userInfo.user_id") ||
        getFromLocalStorage("userInfo.user_id") ||
        null,

    userToken:
        sessionStorage.getItem("userToken") ||
        localStorage.getItem("userToken"),

    userType:
        sessionStorage.getItem("userType") ||
        localStorage.getItem("userType") ||
        "gest",

    isLogin:
        sessionStorage.getItem("isLogin") ||
        getFromLocalStorage("isLogin", false),

    isStaff:
        sessionStorage.getItem("isStaff") ||
        localStorage.getItem("isStaff") ||
        true,

    rememberUser: localStorage.getItem("rememberUser") || false,
};
const mutations = {
    SET_USER_TOKEN(state, token) {
        state.userToken = token;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_IS_LOGGED_IN_TRUE(state) {
        state.isLogin = true;
    },
    SET_USER_TYPE(state, role) {
        state.userType = role;
    },
    SET_IS_IS_APPROVED_TRUE(state) {
        localStorage.setItem("isApproved", true);
        state.isApproved = true;
    },
    SET_IS_IS_STAFF_TRUE(state, isStaff) {
        // localStorage.setItem("isStaff", isStaff);
        state.isStaff = isStaff;
    },
    SET_REMEMBER_USER(state) {
        state.rememberUser = true;
        localStorage.setItem("rememberUser", true);
    },
};

const actions = {
    async login({ commit }, userData) {
        try {
            const response = await axios.post(`${BASE_URL}${LOGIN}`, userData); // Adjust the API endpoint accordingly

            if (response.status == 200) {
                window.location.reload();
                // console.log(response);
                // console.log(response.data.role);

                // Save the raw JWT token
                // Decode the JWT token
                if (localStorage.getItem("rememberUser")) {
                    const token = response.data.access_token;
                    const decodedToken = jwtDecode(token);
                    // this.state.userType = user.roles;

                    localStorage.setItem("userToken", token);
                    commit("SET_USER_TOKEN", token);
                    const user = {
                        ...decodedToken,
                        token,
                    };
                    localStorage.setItem("userInfo", JSON.stringify(user));
                    localStorage.setItem("userType", response.data.role);
                    commit("SET_USER_TYPE", response.data.role);

                    localStorage.setItem("isStaff", response.data.is_staff);
                    commit("SET_IS_IS_STAFF_TRUE", response.data.is_staff);

                    commit("SET_IS_LOGGED_IN_TRUE");
                    localStorage.setItem("isLogin", true);

                    // console.log(response.data.is_staff);
                } else {
                    const token = response.data.access_token;
                    const decodedToken = jwtDecode(token);
                    // this.state.userType = user.roles;

                    sessionStorage.setItem("userToken", token);
                    commit("SET_USER_TOKEN", token);
                    const user = {
                        ...decodedToken,
                        token,
                    };
                    sessionStorage.setItem("userInfo", JSON.stringify(user));
                    sessionStorage.setItem("userType", response.data.role);
                    commit("SET_USER_TYPE", response.data.role);

                    sessionStorage.setItem("isStaff", response.data.is_staff);
                    commit("SET_IS_IS_STAFF_TRUE", response.data.is_staff);

                    commit("SET_IS_LOGGED_IN_TRUE");
                    sessionStorage.setItem("isLogin", true);
                }
                return response;
            } else {
                // console.log("error" + response);
            }
        } catch (error) {
            // Handle login error
            // console.error("Login failed:", error);
            return error;
        }
    },
    logout({ commit }) {
        if (!localStorage.getItem("rememberUser")) {
            localStorage.removeItem("rememberUser");
        }
        commit("SET_USER", null);
        commit("SET_USER_TOKEN", null);
        commit("SET_USER_TYPE", "gest");
        commit("SET_IS_LOGGED_IN_TRUE", false);
        commit("SET_USER_TYPE", "Guest");
        commit("SET_IS_STAFF", false);

        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("isLogin");
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("isApproved");
        sessionStorage.removeItem("userType");
        sessionStorage.removeItem("isStaff");
        sessionStorage.removeItem("existShowSystemIntro");

        // Clear specific items from localStorage
        localStorage.removeItem("userInfo");
        localStorage.removeItem("isLogin");
        localStorage.removeItem("userToken");
        localStorage.removeItem("isApproved");
        localStorage.removeItem("userType");
        localStorage.removeItem("isStaff");
    },
};

const getters = {
    getUser: (state) => state.user,
    getUserId: (state) => state.userId,
    getUserType: (state) => state.userType,
    getIsLogin: (state) => state.isLogin,
    getUserToken: (state) => state.userToken,
    getIsApproved: (state) => state.isApproved,
    getIsStaff: (state) => state.isStaff,
    getRememberUser: (state) => state.rememberUser,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
