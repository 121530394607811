import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "HomeView",
        component: HomeView,
        // beforeEnter: (to, from, next) => {
        //     // Check if the user is already logged in
        //     if (
        //         store.getters.getIsLogin &&
        //         localStorage.getItem("userType") === "SUPER"
        //     ) {
        //         // Redirect to a different route (e.g., home)
        //         next();
        //     } else {
        //         next("/PageNotFound");
        //     }
        // },
        children: [
            {
                path: "/contacts",
                name: "ContactsParentComponent",
                component: () =>
                    import("@/views/contacts/ContactsParentComponent.vue"),
                children: [
                    {
                        path: "/contacts/tenants",
                        name: "TenantsContacts",
                        component: () =>
                            import(
                                "@/components/contacts-components/TenantsContacts.vue"
                            ),
                    },
                    //#region // edit Forms
                    // EditTenant
                    {
                        path: "/contacts/tenants/:id",
                        name: "EditTenant",
                        component: () =>
                            import("@/views/tenants/EditTenant.vue"),
                    },
                    //#endregion // edit Forms

                    {
                        path: "/contacts/brokers",
                        name: "BrokersContacts",
                        component: () =>
                            import(
                                "@/components/contacts-components/BrokersContacts.vue"
                            ),
                    },
                    {
                        path: "/contacts/landlords",
                        name: "LandlordsContacts",
                        component: () =>
                            import(
                                "@/components/contacts-components/LandlordsContacts.vue"
                            ),
                    },
                    //#region // edit Forms
                    {
                        path: "/contacts/landlords/:id",
                        name: "EditLandLord",
                        component: () =>
                            import("@/views/LandLord/EditLandlord.vue"),
                    },
                    //#endregion // edit Forms
                    {
                        path: "/contacts/employee",
                        name: "EmployeeContacts",
                        component: () =>
                            import(
                                "@/components/contacts-components/EmployeeContacts.vue"
                            ),
                    },

                    //#region // edit Forms
                    {
                        path: "/contacts/employee/:id",
                        name: "EditUser",
                        component: () =>
                            import("@/views/user-reg/EditUser.vue"),
                        beforeEnter: (to, from, next) => {
                            // Check if the user is already logged in
                            if (
                                (store.getters.getIsLogin &&
                                    localStorage.getItem("userType") ===
                                        "SUPERUSER") ||
                                (sessionStorage.getItem("userType") ===
                                    "SUPERUSER" &&
                                    store.getters.getIsLogin) ||
                                (store.getters.getIsLogin &&
                                    localStorage.getItem("userType") ===
                                        "OWNER") ||
                                sessionStorage.getItem("userType") === "OWNER"
                            ) {
                                // Redirect to a different route (e.g., home)
                                next();
                            } else {
                                next("/PageNotFound");
                            }
                        },
                    },
                    //#endregion // edit Forms

                    //  Housing Tables
                    // Property
                    {
                        path: "/housing/properties",
                        name: "PropertyTHousing",
                        component: () =>
                            import(
                                "../components/housing-components/PropertyTHousing.vue"
                            ),
                    },
                    {
                        path: "/housing/properties/:id",
                        name: "PropertyTHousing",
                        component: () =>
                            import(
                                "../components/housing-components/RevenueByPropertyTHousing.vue"
                            ),
                    },
                    {
                        path: "/contacts/:notfound",
                        name: "PageNotFound",
                        component: () => import("@/views/PageNotFound.vue"),
                    },
                    // Partition
                    {
                        path: "/housing/partition",
                        name: "PartitionTHousing",
                        component: () =>
                            import(
                                "../components/housing-components/PartitionTHousing.vue"
                            ),
                    },
                    {
                        path: "/housing/partition/:id",
                        name: "PartitionTHousing",
                        component: () =>
                            import(
                                "../components/housing-components/RevenueByPartitionTHousing.vue"
                            ),
                    },
                    //  invoices Tables =====================================
                    // Expenses tables
                    {
                        path: "/invoices/expenses",
                        name: "ExpensesTInvoices",
                        component: () =>
                            import(
                                "@/components/invoices/t-invoices/ExpensesTInvoices.vue"
                            ),
                        children: [
                            // Landlords
                            {
                                path: "/invoices/expenses/landlords",
                                name: "ExpensesTForLandlord",
                                component: () =>
                                    import(
                                        "@/components/invoices/t-invoices/expenses-child-tables/ExpensesTForLandlord.vue"
                                    ),
                            },
                            // Properties
                            {
                                path: "/invoices/expenses/properties",
                                name: "ExpensesTForProperty",
                                component: () =>
                                    import(
                                        "@/components/invoices/t-invoices/expenses-child-tables/ExpensesTForProperty.vue"
                                    ),
                            },
                            // Partitions
                            {
                                path: "/invoices/expenses/partitions",
                                name: "ExpensesTForPartition",
                                component: () =>
                                    import(
                                        "@/components/invoices/t-invoices/expenses-child-tables/ExpensesTForPartition.vue"
                                    ),
                            },
                        ],
                    },

                    // Revenues
                    {
                        path: "/invoices/revenues",
                        name: "ExpensesTInvoices",
                        component: () =>
                            import(
                                "@/components/invoices/t-invoices/RevenuesTInvoices.vue"
                            ),
                    },
                    {
                        path: "/invoices/revenues-monthly-renew",
                        name: "RevenuesTMonthlyRenew",
                        component: () =>
                            import(
                                "@/components/invoices/t-invoices/RevenuesTMonthlyRenew.vue"
                            ),
                    },
                    {
                        path: "/invoices/monthly/revenues",
                        name: "MonthlyRevenues",
                        component: () =>
                            import(
                                "@/components/invoices/t-invoices/MonthlyRevenues.vue"
                            ),
                    },
                    {
                        path: "/invoices/periodic/revenues",
                        name: "MonthlyRevenues",
                        component: () =>
                            import(
                                "@/components/invoices/t-invoices/PeriodicRevenues.vue"
                            ),
                    },
                    {
                        path: "/contacts/:notfound",
                        name: "PageNotFound",
                        component: () => import("@/views/PageNotFound.vue"),
                    },

                    // propertyCheques
                    {
                        path: "/cheques/property",
                        name: "PropertyCheques",
                        component: () =>
                            import("@/components/cheques/PropertyCheques.vue"),
                    },
                    // edit
                    {
                        path: "/cheques/property/:id",
                        name: "PropertyChequesEdit",
                        component: () =>
                            import(
                                "@/components/cheques/PropertyChequesEdit.vue"
                            ),
                    },
                    // ================= tenant Cheques
                    {
                        path: "/cheques/tenant",
                        name: "TenantCheques",
                        component: () =>
                            import("@/components/cheques/TenantCheques.vue"),
                    },
                    // edit
                    {
                        path: "/cheques/tenant/:id",
                        name: "TenantChequesEdit",
                        component: () =>
                            import(
                                "@/components/cheques/TenantChequesEdit.vue"
                            ),
                    },
                    {
                        path: "/cheques/:notfound",
                        name: "PageNotFound",
                        component: () => import("@/views/PageNotFound.vue"),
                    },
                ],
            },
        ],
    },

    //#region // Add Forms
    {
        path: "/add-user",
        name: "AddUser",
        component: () => import("@/views/user-reg/AddUser.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (
                (store.getters.getIsLogin &&
                    localStorage.getItem("userType") === "SUPERUSER") ||
                (sessionStorage.getItem("userType") === "SUPERUSER" &&
                    store.getters.getIsLogin) ||
                (store.getters.getIsLogin &&
                    localStorage.getItem("userType") === "OWNER") ||
                sessionStorage.getItem("userType") === "OWNER"
            ) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },

    {
        path: "/add-landlord",
        name: "AddLandLord",
        component: () => import("@/views/LandLord/AddLandLord.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (store.getters.getIsLogin) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },

    {
        path: "/add-broker",
        name: "AddBroker",
        component: () => import("@/views/brokers/AddBroker.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (store.getters.getIsLogin) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },

    {
        path: "/add-tenant",
        name: "AddBroker",
        component: () => import("@/views/tenants/AddTenant.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (store.getters.getIsLogin) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },

    // ====== Add House Start ========
    // add Property
    {
        path: "/add-property",
        name: "AddProperty",
        component: () => import("@/views/houses/property/AddProperty.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (store.getters.getIsLogin) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },
    // add Partition
    {
        path: "/add-partition",
        name: "AddPartition",
        component: () => import("@/views/houses/partition/AddPartition.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (store.getters.getIsLogin) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },

    // ====== Add House End ========
    // ====== Add InVoices Start  ========
    //Add Expense Create
    {
        path: "/add-expenses",
        name: "AddExpensesPage",
        component: () =>
            import("@/views/invoices/add-expenses/AddExpensesPage.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (store.getters.getIsLogin) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },
    //Add Revenue Create
    {
        path: "/add-revenue",
        name: "AddRevenue",
        component: () => import("@/views/invoices/add-revenue/AddRevenue.vue"),
        beforeEnter: (to, from, next) => {
            // Check if the user is already logged in
            if (store.getters.getIsLogin) {
                // Redirect to a different route (e.g., home)
                next();
            } else {
                next("/PageNotFound");
            }
        },
    },
    // ====== Add InVoices End  ========
    //#endregion

    {
        path: "/test",
        name: "TestPage",
        component: () => import("@/views/TestPage.vue"),
        // beforeEnter: (to, from, next) => {
        //     // Check if the user is already logged in
        //     if (
        //         store.getters.getIsLogin &&
        //         localStorage.getItem("userType") === "SUPERUSER"
        //     ) {
        //         // Redirect to a different route (e.g., home)
        //         next();
        //     } else {
        //         next("/PageNotFound");
        //     }
        // },
    },

    {
        path: "/about-us",
        name: "AboutUs",
        component: () => import("@/views/AboutUs.vue"),
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: () => import("@/views/ContactUs.vue"),
    },

    {
        path: "/:notfound",
        name: "PageNotFound",
        component: () => import("@/views/PageNotFound.vue"),
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    const userIsStaff = localStorage.getItem("isStaff") === "true";

    if (to.meta.requiresStaff && !userIsStaff) {
        // User is not staff, redirect to the Home page or any other appropriate page
        next(false);
    } else {
        // User is staff or the route does not require staff, proceed
        next();
    }
});
export default router;
