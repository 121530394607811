<template>
    <div class="app-footer-container">
        <p class="footer-copyright">
            {{ $t("footer.copyright") }}
        </p>
    </div>
</template>
<script>
export default {
    name: "AppFooter",
};
</script>
<style lang="scss">
.app-footer-container {
    height: var(--app-footer-height);
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100%;
    text-align: center;
    border-top: 1px solid rgb(168, 168, 168);
    color: gray;
}
.footer-copyright {
}
</style>
