<template>
    <div>
        <!-- <b-button type="is-light" @click="openMobileAside = true">
            <i class="fa-solid fa-ellipsis-vertical"></i
        ></b-button> -->
        <!-- :style="{ backgroundColor: chosenColor }" -->
        <section>
            <b-sidebar
                v-on-clickaway="closeTabClickaway"
                v-show="openMobileAside"
                type="is-light"
                :fullheight="fullheight"
                :fullwidth="fullwidth"
                :overlay="overlay"
                :right="right"
                v-model="openMobileAside"
            >
                <div class="p-1" :style="{ backgroundColor: chosenColor }">
                    <div
                        class="d-flex justify-content-around align-items-center pt-2"
                    >
                        <img
                            width="50"
                            src="@/assets/imgs/logo/logo.png"
                            alt="logo"
                        />
                        <router-link class="bg-transparent" to="/">
                            <div class="app-aside-project-name text-white">
                                {{ $t("projectName") }}
                            </div>
                        </router-link>
                    </div>
                    <hr />
                    <!-- Aside content Start -->
                    <div class="aside-content-container text-white">
                        <!-- contacts collapse Start -->

                        <AsideTabComponents
                            :openedAside="false"
                            :asideTabTitle="this.$t('asideTabs.contacts')"
                            :asideTabIconClass="'fa-regular fa-address-book'"
                            :tabChildData="contactsChildData"
                        />
                    </div>
                    <div class="aside-content-container text-white">
                        <!-- contacts collapse Start -->
                        <AsideTabComponents
                            :openedAside="false"
                            :asideTabTitle="this.$t('asideTabs.units')"
                            :asideTabIconClass="'fa-solid fa-hotel'"
                            :tabChildData="contactsChildDataPlaces"
                        />
                    </div>
                    <div class="aside-content-container text-white">
                        <!-- contacts collapse Start -->
                        <AsideTabComponents
                            :openedAside="false"
                            :asideTabTitle="this.$t('asideTabs.invoices')"
                            :asideTabIconClass="'fa-solid fa-sack-dollar'"
                            :tabChildData="contactsChildDataExpenses"
                        />
                    </div>

                    <div class="aside-content-container text-white">
                        <!-- contacts collapse Start -->
                        <AsideTabComponents
                            :openedAside="false"
                            :asideTabTitle="this.$t('cheques.cheques')"
                            :asideTabIconClass="'fa-solid fa-money-check-dollar'"
                            :tabChildData="cheques"
                        />
                    </div>
                </div>
            </b-sidebar>
        </section>
    </div>
</template>

<script>
import AsideTabComponents from "@/components/aside-components/AsideTabComponents.vue";
import store from "@/store";
import { directive as onClickaway } from "vue-clickaway";
export default {
    created() {},
    name: "AppMobileAside",
    components: {
        AsideTabComponents,
    },

    directives: {
        onClickaway: onClickaway,
    },
    async mounted() {},
    props: {
        openMobileAsideProp: {
            type: Boolean,
        },
    },
    data() {
        return {
            openMobileAside: this.openMobileAsideProp || false,
            overlay: false,
            fullheight: true,
            fullwidth: false,
            right: false,

            bgColor:
                localStorage.getItem("color") || "var(--aside-bg-color-blue)",

            // ======
            showColorAside: false,
            lang: localStorage.getItem("lang") || "en",

            selectedColorValue: this.$store.state.selectedColor,
            arrowDirectionClosed: true,
            showContactsChild: false,
            contactsIsHovered: false,

            // contacts component data

            asideTabIconClass: "",
            asideTabTitle: "",

            contactsChildData: [
                {
                    id: 1,
                    tabName: this.$t("asideTabs.contactsEmployee"),
                    to: "/contacts/employee",
                },
                {
                    id: 2,
                    tabName: this.$t("asideTabs.contactsOwners"),
                    to: "/contacts/landlords",
                },
                {
                    id: 3,
                    tabName: this.$t("asideTabs.contactsBrokers"),
                    to: "/contacts/brokers",
                },
                {
                    id: 4,
                    tabName: this.$t("asideTabs.contactsTenants"),
                    to: "/contacts/tenants",
                },
            ],
            contactsChildDataPlaces: [
                {
                    id: 1,
                    tabName: this.$t("asideTabs.property"),
                    to: "/housing/properties",
                },
                {
                    id: 2,
                    tabName: this.$t("asideTabs.partition"),
                    to: "/housing/partition",
                },
            ],
            contactsChildDataExpenses: [
                {
                    id: 1,
                    tabName: this.$t("asideTabs.expenses"),
                    to: "/invoices/expenses/landlords",
                },
                {
                    id: 2,
                    tabName: this.$t("asideTabs.revenue"),
                    to: "/invoices/revenues",
                },
                {
                    id: 3,
                    tabName: this.$t("asideTabs.revenueMonthlyRenew"),
                    to: "/invoices/revenues-monthly-renew",
                },
            ],
            cheques: [
                {
                    id: 1,
                    tabName: this.$t("cheques.propertyCheques"),
                    to: "/cheques/property",
                },
                {
                    id: 2,
                    tabName: this.$t("cheques.tenantCheques"),
                    to: "/cheques/tenant",
                },
            ],
        };
    },
    computed: {
        chosenColor() {
            // return localStorage.getItem("color");
            return store.getters.getSelectedColor;
        },
    },
    watch: {},
    methods: {
        closeTabClickaway() {
            this.openMobileAside = false;
            this.toggleMobileAside();
            // console.log(this.showContactsChild, "this.showContactsChild");
        },
        toggleMobileAside() {
            this.$emit("toggleMobileAside");
            // console.log("emit open mobile aside sent");
        },

        // change Color from input
        updateSelectedColor() {
            store.commit("SET_SELECTED_COLOR", this.selectedColorValue);
            localStorage.setItem("color", this.selectedColorValue);
        },
        changeAsideBgColor(colorVariable) {
            this.bgColor = `var(--${colorVariable})`;
            localStorage.setItem("color", this.bgColor);
            store.commit("SET_SELECTED_COLOR", `var(--${colorVariable})`);
            // window.location.reload();
        },
        closeAsideBgColors() {
            this.showColorAside = false;
        },
        openWithHover() {
            this.$emit("openWithHover");
        },
    },
};
</script>
<style lang="scss" scoped>
.p-1 {
    padding: 1em;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999999999;
}
</style>
