const state = {
    lang: localStorage.getItem("lang") || "en",
};
const mutations = {
    SET_LANG(state, lang) {
        state.lang = lang;
    },
};

const actions = {};
const getters = {};
export default {
    state,
    mutations,
    actions,
    getters,
};
