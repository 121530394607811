<template>
    <div class="summary-all-expenses-by-property">
        <LoadingComponentVue :isLoading="isLoading" />

        <div class="">
            <div class="card all-expenses-summary-card">
                <div class="card-body">
                    <div class="d-flex align-items-top justify-content-between">
                        <div>
                            <span
                                class="avatar avatar-md avatar-rounded bg-secondary"
                            >
                                <i class="ti ti-wallet fs-16"></i>
                            </span>
                        </div>
                        <div class="flex-fill ms-3">
                            <div
                                class="d-flex align-items-center justify-content-between flex-wrap"
                            >
                                <div>
                                    <div>
                                        <p class="mb-0 d-inline-block">
                                            إجمالي مصروفات شقة
                                        </p>
                                    </div>
                                    <!-- Select Property  -->
                                    <div class="input-sup-container">
                                        <b-field
                                            :label="$t('partition.property')"
                                            :label-position="labelPosition"
                                            :type="
                                                isValidProperty
                                                    ? 'is-success'
                                                    : 'is-danger' &&
                                                      !isSubmitClicked
                                                    ? ''
                                                    : 'is-danger'
                                            "
                                            :message="
                                                isValidProperty
                                                    ? ''
                                                    : $t(
                                                          'addUserValidation.required'
                                                      )
                                            "
                                        >
                                            <b-select
                                                @input="
                                                    getPropertyExpensesSummary()
                                                "
                                                :placeholder="
                                                    propertiesDialog.length ===
                                                    0
                                                        ? 'loading'
                                                        : 'Select a type'
                                                "
                                                v-model="formData.property"
                                                expanded
                                                :loading="
                                                    propertiesDialog.length ===
                                                    0
                                                "
                                            >
                                                <option selected disabled>
                                                    {{ $t("select") }}
                                                </option>
                                                <option
                                                    v-for="(
                                                        property, index
                                                    ) in propertiesDialog"
                                                    :key="index"
                                                    :value="`${property.property_id}`"
                                                >
                                                    <span>{{
                                                        property.title
                                                    }}</span>
                                                </option>
                                            </b-select>
                                        </b-field>
                                    </div>
                                    <!-- ========== select input property End ============= -->
                                    <h4 class="fw-semibold mt-1">
                                        <span class="fs-6 mx-1"
                                            >{{ $t("currency") }}
                                        </span>
                                        <span>{{ summaries.total_sum }}</span>
                                    </h4>
                                </div>
                                <!-- <AllExpensesCircleChart :data="summaries" /> -->
                                <!-- <Bar :data="data" :options="options" /> -->
                                <div
                                    id="crm-total-revenue"
                                    style="min-height: 40px"
                                >
                                    <div
                                        id="apexchartsl7rj4trk"
                                        class="apexcharts-canvas apexchartsl7rj4trk apexcharts-theme-light"
                                        style="width: 100px; height: 40px"
                                    >
                                        <img
                                            width="30"
                                            src="../../assets/imgs/app-header-icons/expenses-icon.png"
                                        />
                                    </div>
                                    <p
                                        v-if="
                                            summaries.partitions_expenses_total_amount
                                        "
                                    >
                                        Partitions :
                                        {{
                                            summaries.partitions_expenses_total_amount
                                        }}
                                    </p>
                                    <p
                                        v-if="
                                            summaries.payment_to_landlords_total_amount
                                        "
                                    >
                                        landlords
                                        {{
                                            summaries.payment_to_landlords_total_amount
                                        }}
                                    </p>
                                    <p
                                        v-if="
                                            summaries.partitions_expenses_total_amount
                                        "
                                    >
                                        property
                                        {{
                                            summaries.partitions_expenses_total_amount
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-between mt-1"
                            >
                                <div>
                                    <router-link
                                        to="/invoices/expenses/landlords"
                                        class=""
                                        href="javascript:void(0);"
                                        >{{ $t("viewAll")
                                        }}<i
                                            class="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block"
                                        ></i
                                    ></router-link>
                                </div>
                                <div class="text-end">
                                    <p class="mb-0 text-success fw-semibold">
                                        <router-link to="/add-expenses">
                                            +
                                        </router-link>
                                    </p>
                                    <span class="text-muted op-7 fs-11"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import AllExpensesCircleChart from "@/components/home/AllExpensesCircleChart.vue";
import LoadingComponentVue from "../animations/LoadingComponent";
import { SUMMARY_FOR_PROPERTY_EXPENSES, PROPERTY_DIALOG } from "@/utils/api.js";
import axios from "axios";
import { BASE_URL } from "@/utils/constants";
import store from "@/store";

// chart
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";
// import { Bar } from "vue-chartjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default {
    name: "PropertyExpensesSummary",
    components: {
        LoadingComponentVue,
        // Bar,
        // AllExpensesCircleChart,
    },

    data() {
        return {
            isLoading: false,
            summaries: {},
            propertiesDialog: [],
            // charts
            data: {
                labels: ["partitions", "property"],
                datasets: [
                    {
                        data: [
                            parseFloat(
                                localStorage.getItem(
                                    "partitionsChartByProperty"
                                )
                            ),
                            // parseFloat(localStorage.getItem("landlordsChartByProperty")),
                            parseFloat(
                                localStorage.getItem("propertyChartByProperty")
                            ),
                            // 300,
                        ],
                    },
                ],
            },
            options: {
                responsive: true,
            },

            lang: localStorage.getItem("lang"),

            isFullPage: true,

            labelPosition: "on-border",
            showKeepToAdd: false,
            isSubmitClicked: false,
            errorMsgs: [],
            // dialogs

            formData: {
                property: "", // required
                payment_type: "", // required
                amount: null,
                deposit: 0,
                payment_date: null,
                cheques_count: null,
                description: "", // rent Description
            },
        };
    },

    computed: {
        isValidProperty() {
            return this.formData.property.trim() !== "";
        },
    },

    async mounted() {
        await this.getPropertyDialog();
    },

    methods: {
        async getPropertyDialog() {
            try {
                const response = await axios.get(
                    `${BASE_URL}${PROPERTY_DIALOG}`,
                    {
                        headers: {
                            Authorization: `Bearer ${store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    this.propertiesDialog = response.data;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getPropertyExpensesSummary() {
            this.isLoading = true;
            try {
                const response = await axios.get(
                    `${BASE_URL}${SUMMARY_FOR_PROPERTY_EXPENSES}`,
                    {
                        headers: {
                            Authorization: `Bearer ${store.getters.getUserToken}`,
                        },
                        params: {
                            property_id: this.formData.property,
                        },
                    }
                );
                if (response.status === 200) {
                    this.summaries = response.data;
                    // this.renderChart(this.chartData, this.summaries);
                    localStorage.setItem(
                        "partitionsChartByProperty",
                        response.data.partitions_expenses_total_amount
                    );
                    localStorage.setItem(
                        "propertyChartByProperty",
                        response.data.properties_expenses_total_amount
                    );

                    localStorage.setItem(
                        "landlordsChartByProperty",
                        response.data.payment_to_landlords_total_amount
                    );

                    this.isLoading = false;

                    // console.log(response);
                    // console.log(response.data);
                }
            } catch (error) {
                if (error) {
                    console.log(error);
                    this.isLoading = false;
                }
            }
        },
    },
};
</script>

<style lang="scss">
.summary-all-expenses {
    // min-height: var(--min-height-page);
}
.all-expenses-summary-card-by-property {
    min-height: 360px;
    &:hover {
        background-color: rgb(238 245 255);
        color: black !important;
        transform: translateY(-5px);
        transition: all 0.5s ease-in-out;
    }
}
</style>
