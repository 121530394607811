<template>
    <div class="app-header-container">
        <LoadingComponent :isLoading="isLoading" />
        <div class="app-header-logo-name-container">
            <div>
                <i
                    @click="toggleAsideApp()"
                    class="fa-solid fa-bars fs-4 m-2 app-header-menu-icon text-dark"
                ></i>
                <!-- <i
                    @click="toggleMobileAsideApp()"
                    class="fa-solid fa-bars fs-4 m-2 app-header-mobile-menu-icon text-dark"
                ></i> -->
                <b-button
                    class="app-header-mobile-menu-icon"
                    type="is-light"
                    @click="toggleMobileAside()"
                >
                    <i class="fa-solid fa-ellipsis-vertical"></i
                ></b-button>
            </div>
            <router-link class="bg-transparent" to="/">
                <span class="app-header-project-name">
                    {{ $t("projectName") }}
                </span>
            </router-link>

            <div
                @click="notificationsNow()"
                class="app-header-notification-icon-container"
            >
                <span class="app-header-notification-icon-span">
                    <i class="fa-regular fa-bell fs-5"></i>
                </span>
                <!-- this updated hostinger line -->
                <span
                    v-if="tenantNots && propertyNots"
                    class="app-header-notification-length-span"
                    :class="!allNotificationsLength ? 'd-none' : 'bg-danger'"
                >
                    <!-- {{ allNotificationsLength }} -->
                    {{ tenantNots.length + propertyNots.length }}
                </span>
            </div>

            <!-- Admin and add fast actions links Start   -->
            <!-- v-if="userType == 'SUPERUSER'" -->
            <div
                class="app-header-admin-tools-menu-container"
                @mouseenter="showAdminTools = true"
                @mouseleave="showAdminTools = false"
            >
                <b-tooltip
                    :label="$t('adminTools')"
                    position="is-right"
                    type="is-light"
                >
                    <b-icon
                        style="color: black; cursor: pointer"
                        pack="fas"
                        icon="tachometer-alt"
                        size="is-large"
                    >
                    </b-icon>
                </b-tooltip>
                <div
                    v-if="showAdminTools"
                    class="app-header-actions-container"
                    :style="{ backgroundColor: chosenColor }"
                >
                    <ul class="app-header-actions-ul">
                        <li title="add-user" class="app-header-actions-ul-li">
                            <router-link
                                exact
                                :to="{ name: 'AddUser' }"
                                class="bg-transparent mt-0"
                                style=""
                            >
                                <i
                                    class="fa-solid fa-user-plus app-header-add-user-link-icon"
                                ></i>
                                <span class="">
                                    {{ $t("userRegistration.addUser") }}</span
                                >
                            </router-link>
                        </li>
                        <li title="add-user" class="app-header-actions-ul-li">
                            <router-link to="/add-landlord">
                                <i
                                    class="fa-solid fa-user-plus app-header-add-user-link-icon"
                                ></i>
                                <span class="">
                                    {{ $t("userRegistration.addLandlord") }}
                                </span>
                            </router-link>
                        </li>
                        <li title="add-tenant" class="app-header-actions-ul-li">
                            <router-link to="/add-broker">
                                <i
                                    class="fa-solid fa-user-plus app-header-add-user-link-icon"
                                ></i>
                                <span class="">{{
                                    $t("userRegistration.addBroker")
                                }}</span>
                            </router-link>
                        </li>
                        <li title="add-broker" class="app-header-actions-ul-li">
                            <router-link to="/add-tenant">
                                <i
                                    class="fa-solid fa-user-plus app-header-add-user-link-icon"
                                ></i>
                                <span class="">{{
                                    $t("userRegistration.addTenant")
                                }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- fast actions links End   -->

            <!-- add Property and Partition Start -->
            <div
                @mouseenter="showAddHouse = true"
                @mouseleave="showAddHouse = false"
            >
                <div class="">
                    <!-- <i
                        :style="{ color: chosenColor }"
                        class="fa-solid fa-house-user add-hose-data-icon"
                    ></i> -->
                    <img
                        data-v-6104ddfa=""
                        width="60px"
                        src="../../assets/imgs/app-header-icons/house-icon.png"
                        alt=""
                    />
                </div>
                <div
                    v-if="showAddHouse"
                    :style="{ backgroundColor: chosenColor }"
                    class="app-header-actions-container"
                >
                    <ul class="app-header-actions-ul">
                        <li
                            title="add-Property"
                            class="app-header-actions-ul-li"
                        >
                            <router-link to="/add-property">
                                <i
                                    class="fa-regular fa-building app-header-add-user-link-icon"
                                ></i>
                                <span>{{ $t("property.toProperty") }}</span>
                            </router-link>
                        </li>
                        <li
                            title="add-Partition"
                            class="app-header-actions-ul-li"
                        >
                            <router-link to="/add-partition">
                                <i
                                    class="fa-regular fa-building app-header-add-user-link-icon"
                                ></i>
                                <span> {{ $t("partition.toPartition") }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- add Property and Partition End -->

            <!-- add Expenses Start -->
            <div
                @mouseenter="showAddExpenses = true"
                @mouseleave="showAddExpenses = false"
            >
                <div class="">
                    <!-- <i
                        :style="{ color: chosenColor }"
                        class="fa-solid fa-wallet add-expenses-data-icon"
                    ></i> -->
                    <img
                        data-v-6104ddfa=""
                        width="30px"
                        src="../../assets/imgs/app-header-icons/expenses-icon.png"
                        alt=""
                    />
                </div>
                <div
                    v-if="showAddExpenses"
                    :style="{ backgroundColor: chosenColor }"
                    class="app-header-actions-container"
                >
                    <ul class="app-header-actions-ul">
                        <li
                            :style="{ color: chosenColor }"
                            title="add-Expenses"
                            class="app-header-actions-ul-li"
                        >
                            <router-link to="/add-expenses">
                                <img
                                    data-v-6104ddfa=""
                                    width="30px"
                                    src="/img/payment-1.dcbfb00a.svg"
                                    alt=""
                                />
                                <span> {{ $t("invoices.addExpenses") }}</span>
                            </router-link>
                        </li>
                        <li
                            :style="{ color: chosenColor }"
                            title="add-Revenue"
                            class="app-header-actions-ul-li"
                        >
                            <router-link to="/add-revenue">
                                <img
                                    data-v-6104ddfa=""
                                    width="30px"
                                    src="../../assets/imgs/app-header-icons/revenue-header.png"
                                    alt=""
                                />
                                <span> {{ $t("invoices.addRevenue") }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <!-- add Expenses  End -->
            </div>
        </div>

        <div
            v-on-clickaway="closeTabClickaway"
            :style="{ backgroundColor: chosenColor }"
            class="app-header-btn-actions-container"
        >
            <!-- ============================ user Profile Start ============================ -->

            <div class="user-profile-menu">
                <!-- @mouseenter="showProfileMenu = true"
                @mouseleave="showProfileMenu = false" -->
                <div
                    @click="showProfileMenu = !showProfileMenu"
                    class="user-profile-image-container rounded-circle"
                >
                    <img
                        v-if="userData && userData.photo"
                        width="40"
                        :src="userData.photo"
                        alt="profile"
                        class="rounded-circle bg-light"
                    />
                    <img
                        v-else
                        width="40"
                        src="../../assets/imgs/user-1.png"
                        alt="profile"
                        class="rounded-circle bg-light"
                    />
                </div>
                <div v-if="showProfileMenu" class="user-profile-menu-container">
                    <UserMenu style="position: fixed" />
                </div>

                <!--============================ user Profile end ============================-->
            </div>
            <!-- ============================ Logout Start ============================ -->
            <ul>
                <li v-if="$store.getters.getIsLogin" class="">
                    <button
                        class="btn btn-light app-header-logout-btn"
                        @click="logout()"
                    >
                        {{ $t("userLogin.logout") }}

                        <!-- <i
                            class="fa-solid fa-arrow-right-from-bracket app-header-logout-icon"
                        ></i> -->
                    </button>
                </li>
            </ul>
            <!-- ============================ Logout end ============================ -->
            <button
                class="lang-btn"
                v-show="langSwitch == 'en' || langSwitch == null"
                @click="changLangToAr()"
            >
                <img
                    width="15"
                    src="@/assets/imgs/app-header-icons/arabic-icon2.png"
                    alt=""
                />
            </button>
            <button
                class="lang-btn"
                v-show="langSwitch == 'ar'"
                @click="changLangToEn()"
            >
                <img
                    width="50"
                    src="@/assets/imgs/app-header-icons/us_flag.jpg"
                    alt=""
                />
            </button>
        </div>
    </div>
</template>
<script>
// import router from "@/router";
import LoadingComponent from "@/components/animations/LoadingComponent.vue";
import store from "@/store";
import { directive as onClickaway } from "vue-clickaway";

import axios from "axios";
import {
    NOTIFICATIONS_PROPERTY_LIST,
    CHECK_CHEQUES,
    NOTIFICATIONS_TENANT_LIST,
    USER_PROFILE,
} from "@/utils/api";
import { BASE_URL } from "@/utils/constants";

import UserMenu from "@/components/user-components/UserMenu.vue";
export default {
    name: "AppHeader",
    components: {
        LoadingComponent,
        UserMenu,
    },

    async mounted() {
        // await this.checkCheques();
        this.getUserProfile();

        // Get the last execution date from localStorage
        const lastExecutionDate = localStorage.getItem(
            "lastCheckChequesExecution"
        );

        // Get the current date
        const currentDate = new Date().toISOString().split("T")[0];

        // Check if the function has been executed today
        if (lastExecutionDate !== currentDate) {
            await this.checkCheques();

            // Update the last execution date in localStorage to today
            localStorage.setItem("lastCheckChequesExecution", currentDate);
        }
    },

    directives: {
        onClickaway: onClickaway,
    },
    props: {
        openedAside: {
            type: Boolean,
        },
    },
    created() {
        const tenHours = 10 * 60 * 60 * 1000;
        setTimeout(() => {
            this.$store.dispatch("logout");
            if (this.$route.path != "/") {
                this.$router.push("/");
            }
            window.location.reload();
        }, tenHours);
    },
    data() {
        return {
            userData: {},
            isLogin: false,
            isLoading: false,
            // add persons user landlords broker tenant
            showAdminTools: false,

            // add Houses Menu property partition
            showAddHouse: false,
            showAddExpenses: false,

            // =======================
            showNotifications: false,
            langSwitch: localStorage.getItem("lang") || "en",

            bgColor: store.state.selectedColor || localStorage.getItem("color"),

            showProfileMenu: false,
            // showProfileMenu: true,

            userType: store.getters.getUserType,

            // mobile screen

            showMobileAsideApp: false,

            // notifications
            // propertyNotifications: [],
            // tenantNotifications: [1, 2, 3],

            // propertyNots: [],
            // tenantNots: [],

            propertyNots: JSON.parse(localStorage.getItem("propertyNots")),
            tenantNots: JSON.parse(localStorage.getItem("tenantNots")),

            // =======
        };
    },
    computed: {
        chosenColor() {
            // return localStorage.getItem("color");
            return store.getters.getSelectedColor;
        },
        allNotificationsLength() {
            const propertyNots =
                JSON.parse(localStorage.getItem("propertyNots")) || [];
            const tenantNots =
                JSON.parse(localStorage.getItem("tenantNots")) || [];

            if (propertyNots != null || tenantNots !== null) {
                const PNotsL = propertyNots.length;
                const TNotsL = tenantNots.length;
                return PNotsL + TNotsL;
            } else {
                return 0;
            }
        },
    },
    // watch: {
    //     "this.bgColor": "this.updateColor()",
    // },
    methods: {
        // check cheques
        async checkCheques() {
            this.isLoading = true;
            try {
                const response = await axios.get(
                    `${BASE_URL}${CHECK_CHEQUES}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    // console.log(response);
                    this.getNotifications();
                    this.getNotificationsTenant();
                }
            } catch (error) {
                if (error) {
                    this.isLoading = false;
                    console.log(error.response);
                }
            }
        },
        async getNotifications() {
            try {
                const response = await axios.get(
                    `${BASE_URL}${NOTIFICATIONS_PROPERTY_LIST}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    this.propertyNots = response.data;
                    localStorage.setItem(
                        "propertyNots",
                        JSON.stringify(this.propertyNots)
                    );
                    if (this.propertyNots == []) {
                        localStorage.setItem("propertyNots", []);
                    }
                    // console.log(response);
                }
                // this.success(response.data.message);
                // this.getNotifications();
            } catch (error) {
                if (error) {
                    this.isLoading = false;
                    console.log(error.response);
                }
            }
        },
        async getNotificationsTenant() {
            try {
                const response = await axios.get(
                    `${BASE_URL}${NOTIFICATIONS_TENANT_LIST}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getUserToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    this.tenantNots = response.data;
                    localStorage.setItem(
                        "tenantNots",
                        JSON.stringify(this.tenantNots)
                    );
                    if (this.tenantNots == []) {
                        localStorage.setItem("tenantNots", []);
                    }
                    // console.log(response);
                }
                // this.success(response.data.message);
                // this.getNotifications();
            } catch (error) {
                if (error) {
                    this.isLoading = false;
                    console.log(error.response);
                }
            }
        },

        // Close Aside App
        toggleAsideApp() {
            this.$emit("toggleAsideApp");
        },
        // ==============
        changLangToAr() {
            this.$store.commit("SET_AR_LANG", "ar");
            localStorage.setItem("lang", "ar");
            this.langSwitch = "ar";
            window.location.reload();
        },
        changLangToEn() {
            this.$store.commit("SET_AR_LANG", "en");
            localStorage.setItem("lang", "en");
            this.langSwitch = "en";
            window.location.reload();
        },
        // ...mapActions("userLogin", ["logout"]),
        // this.$router.push("/");
        logout() {
            this.$store.dispatch("logout");
            window.location.reload();
        },
        closeTabClickaway() {
            this.showProfileMenu = false;
        },

        toggleMobileAside() {
            this.$emit("toggleMobileAside");
        },

        // notifications
        notificationsNow() {
            this.$emit("notificationsNow");
        },

        async getUserProfile() {
            this.isLoading = true;
            try {
                const userToken = store.getters.getUserToken;
                const userId = store.getters.getUser.user_id;
                const response = await axios.get(`${BASE_URL}${USER_PROFILE}`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                    params: { user_id: userId },
                });
                if (response.status == 200) {
                    this.userData = response.data;
                    this.isLoading = false;
                } else {
                    this.errorMsg = response.data.detail;
                    this.userData = response.data;
                }
            } catch (error) {
                if (error) {
                    // this.errorMsg = error.response.data.detail;
                    this.isLoading = false;
                }
                setTimeout(() => {
                    this.errorMsg = "";
                }, 5000);
            }
        },
    },
};
</script>
<style lang="scss">
.app-header-container {
    height: var(--app-header-height);
    width: var(--app-header-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}
.app-header-menu-icon {
    width: 39px;
    margin-bottom: 31px;
    &:hover {
        cursor: pointer;
    }
}
.app-header-mobile-menu-icon {
    width: 39px;
    margin-bottom: 31px;
    &:hover {
        cursor: pointer;
    }
    display: none !important;
}
.app-header-logout-btn {
    font-size: 8pt !important;
    font-weight: 700 !important;
}
@media screen and (max-width: 900px) {
    .app-header-menu-icon {
        display: none !important;
    }
    .app-header-logout-icon {
        display: none !important;
    }
    .app-header-mobile-menu-icon {
        display: inline-block !important;
    }
    .app-header-project-name {
        display: none;
    }
}

// .app-header-menu-icon-open {
//     &:hover {
//         transition: all 0.5s ease-in-out;
//         transform: translateX(-7px);
//     }
// }
// .app-header-menu-icon-close {
//     &:hover {
//         transform: translateX(6px);
//         transform: rotate(180deg);
//     }
// }
.app-header-logo-name-container {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
}
.app-header-project-name {
    font-size: 10pt;
    color: #3a5892;
    color: rgb(172 172 102);
    font-weight: 700;
    text-shadow: 3px 3px 6px #d1d1d1;
}
.open-sidebar-icon {
}
// action container Start
.app-header-btn-actions-container {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2%;
    gap: 10px;
    height: 100%;
    margin-top: 5px;
    margin: 5px 12px 0px 12px;
    border-bottom-left-radius: 50px 20px;
    border-top-left-radius: 50px 20px;
    border-bottom-right-radius: 50px 20px;
    border-top-right-radius: 50px 20px;
}
// ========= admin action nav
.app-header-admin-tools-menu-container {
    position: relative;
    margin: 0 20px;
    &::before {
        content: "Admin";
        position: absolute;
        top: -5px;
        left: 0%;
        font-size: 16pt;
        font-weight: 700;
        color: rgb(93, 204, 255);
        background-color: rgb(231, 231, 231);
        border-radius: 50%;
        height: 60px;
        width: 60px;

        opacity: 0.7;

        display: flex;
        justify-content: center;
    }
    & i {
        font-size: 25pt;
        margin-top: 20px;
    }
}
.app-header-actions-container {
    width: 15rem;
    // min-height: 15rem;
    position: absolute;
    border-radius: 15px;
    background-color: #7d0e0ebc;
    backdrop-filter: blur(10px);
    z-index: 9999;

    display: grid;
    justify-items: start;
    padding: 10px 2px;
}

// action Links Start
.app-header-actions-ul {
    width: 90%;
    margin: 0 auto;
}
.app-header-actions-ul-li {
    background-color: rgb(65, 65, 65);
    padding: 5px;
    border-radius: 15px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    & a {
        color: #ffffff !important;
    }
    &:hover {
        background-color: rgb(54, 54, 54);
        & a:hover {
            color: #13d0ff !important;
        }
    }
    & a.router-link-exact-active.router-link-active {
        background-color: transparent;
        color: #3ae77c !important;
    }
    & {
        i {
            display: inline-block;
            width: 50px;
        }
    }
    & {
        a {
            display: inline-block;
            width: 150px;
        }
    }
}
.app-header-add-user-link-icon {
    font-size: 16pt !important;
    background-color: transparent !important;
    margin-top: 0px !important;
    color: rgb(208, 208, 208);
}
// action Links End
// ===============
.user-profile-menu {
    position: relative;
}
.user-profile-image-container {
    &:hover {
        cursor: pointer;
    }
}
.user-profile-menu-container {
    border-radius: 10px;
    position: absolute;
    z-index: 999;

    box-shadow: 2px 3px 18px 0px #b1b1b1;
    background-color: #efefef6e;
    backdrop-filter: blur(10px);
    padding: 10px;
    min-width: 180px;
    min-height: 250px;
}
// action container End
.app-header-links-nav {
    // background-color: white;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    padding: 0 19px;
    & ul {
        display: flex;
        gap: 30px;
        & li {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
        }
    }
    & ul li a {
        width: auto;
        text-decoration: none;
        color: #777;
        display: inline-block;
        font-size: 12pt;
        text-transform: uppercase;
        font-weight: 600;
        &:hover {
            color: #03a9f4;
        }
    }
}
.app-header-links-down-icon {
    font-size: 8pt;
    color: #777;
    margin: 3px;
}

// notifications
.notifications-icon-container {
    position: relative;
    z-index: 100;
}
.notifications-icon {
    cursor: pointer;
}
.notifications-menu {
    min-width: 152px;
    width: 50vw;
    min-height: 200px;
    background-color: rgb(0 89 133 / 88%);
    background-color: rgb(4 58 53 / 90%);
    padding: 5px;
    border: 1px solid #fbe3c6;
    border-radius: 5px;
    // border-top-left-radius: 0px;
    position: absolute;
    top: 23px;
    left: 15px;
    animation: notificationsAnimation 1s ease-in-out;
    z-index: 99;
    color: white;
    text-align: center;
    box-shadow: 6px 8px 16px #6b4917ad;
    overflow: hidden;
    & a.router-link-active {
        background-color: rgba(128, 128, 128, 0);
    }
    & a {
        text-decoration: none;
        color: white !important;
        display: block;
        width: 80%;
        margin: 5px auto 10px 3px;
        margin: 5px auto;

        border: 1px solid white;
        border-radius: 5px;
        &:hover {
            width: 99%;
            text-decoration: none;
            background-color: #4a798e;
            transition: all 0.4s ease-in-out;

            // scale: 1.1;
        }
    }
}
.lang-btn {
    width: 30px;
    height: 30px;
    // background-color: #03a9f4;
    border-radius: 50%;
    font-size: 7pt;
    color: white;

    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes notificationsAnimation {
    from {
        // transform: translateX(-100px);
        opacity: 0;
        min-height: 0px;
        max-height: 0px;
        height: 0px;
    }
    to {
        opacity: 1;
        min-height: 200px;
        max-height: auto;
        height: 100%;
    }
}

// router link
.router-link-active,
.router-link-exact-active a {
    color: #03a9f4 !important;
    text-decoration: none;
    background-color: whitesmoke;
}
.router-link-active {
    color: #03a9f4;
}

.add-hose-data-icon,
.add-expenses-data-icon {
    font-size: 20pt;
    margin-top: 17px;
    // color: #3a5892;
    &:hover {
        cursor: pointer;
        color: rgb(186, 71, 9);
    }
}
// ========= notifications
.app-header-notification-icon-container {
    cursor: pointer;
    position: relative;
}
.app-header-notification-length-span {
    position: absolute;
    top: -8px;
    left: -5px;
    background-color: #f4b403;
    color: white;
    padding: 2px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
