<template>
    <div>
        <LoadingComponentVue :isLoading="isLoading" />
        <div class="container">
            <div class="profit-loos-cards-container">
                <!-- card -->
                <!-- from date  -->
                <div v-show="fromTo" class="input-sup-container">
                    <span
                        class="text-secondary bg-white p-1 px-2 my-2 d-inline-block rounded-1 fs-5"
                    >
                        من
                    </span>
                    <!-- :label="'الفترة من'" -->
                    <b-field :label-position="'is-right'" :type="'is-primary'">
                        <i
                            class="fa-solid fa-calendar-days fs-2 mx-2 text-secondary"
                        ></i>
                        <b-datepicker
                            style="direction: ltr"
                            v-model="formattedFrom"
                            placeholder="Click to select..."
                            icon="calendar-today"
                            trap-focus
                        >
                        </b-datepicker>
                    </b-field>
                </div>
                <!-- from_date End -->

                <!-- to date  -->
                <div v-show="fromTo" class="input-sup-container">
                    <span
                        class="text-secondary bg-white p-1 px-2 my-2 d-inline-block rounded-1 fs-5"
                    >
                        الي
                    </span>
                    <!-- :label="'الفترة من'" -->
                    <b-field :label-position="'is-right'" :type="'is-primary'">
                        <i
                            class="fa-solid fa-calendar-days fs-2 mx-2 text-secondary"
                        ></i>
                        <b-datepicker
                            style="direction: ltr"
                            v-model="formattedTo"
                            placeholder="Click to select..."
                            icon="calendar-today"
                            trap-focus
                        >
                        </b-datepicker>
                    </b-field>
                </div>
                <!-- to_date End -->
                <div class="card card-for-period">
                    <button
                        @click="getProfitOrLoss()"
                        v-if="fromTo"
                        class="btn btn-success"
                    >
                        بحث من : الي
                    </button>
                    <div class="profit-loss-container-flex">
                        <div class="">
                            <div>
                                <img
                                    class="m-2 mb-5"
                                    src="@/assets/imgs/app-header-icons/house-icon.png"
                                    alt=""
                                />
                            </div>
                            <h6
                                class="text-center p-3 text-dark bg-light rounded-2"
                            >
                                <span
                                    style="
                                        text-shadow: 1px 2px 4px #ddd;
                                        font-weight: 800;
                                    "
                                    class="text-success"
                                >
                                    {{ $t("summary.totalAmountProfit") }}
                                </span>
                            </h6>

                            <select
                                v-show="!fromTo"
                                class="w-100 ms-3"
                                @change="getProfitOrLoss()"
                                v-model="timeRange"
                            >
                                <option value="3">اخر 3 شهور</option>
                                <option value="6">اخر 6 شهور</option>
                                <option value="12">اخر 12 شهر</option>
                                <option value="">منذ بداية التسجيل</option>
                            </select>

                            <span
                                v-if="fromTo"
                                @click="
                                    fromTo = false;
                                    resetDataFromTo();
                                "
                                class="btn btn-info my-3 w-100"
                                >اختر اخر
                            </span>
                            <span
                                v-else
                                @click="fromTo = true"
                                class="btn btn-info my-3 w-100"
                            >
                                اختر بالفترة من : الي
                            </span>
                        </div>

                        <!-- total expenses -->
                        <div
                            class="card-body d-flex justify-content-center align-items-center flex-wrap gap-1"
                        >
                            <p class="profit-loss-paragraph">
                                {{ $t("summary.totalRevenues") }}
                                <span>
                                    <ICountUp
                                        :delay="delay"
                                        :endVal="endValTotalRevenues"
                                        :options="options"
                                        @ready="onReady3"
                                    />
                                    <!-- {{ summaries.total_sum_for_expenses }} -->
                                </span>
                            </p>
                            <p class="profit-loss-paragraph">
                                {{ $t("summary.totalExpenses") }}
                                <span>
                                    <ICountUp
                                        :delay="delay"
                                        :endVal="endVal"
                                        :options="options"
                                        @ready="onReady"
                                    />
                                    <!-- {{ summaries.total_sum_for_expenses }} -->
                                </span>
                            </p>
                            <div
                                class="profit-loss-paragraph"
                                :class="
                                    summaries.profit_or_loss_total_amount > 0
                                        ? 'text-success'
                                        : 'text-danger'
                                "
                            >
                                <h4
                                    v-if="
                                        summaries.profit_or_loss_total_amount >
                                        0
                                    "
                                    class="w-100 text-capitalize text-center align-self-sm-start text-success"
                                >
                                    {{ $t("summary.totalAmountProfit") }}
                                </h4>
                                <h4
                                    v-if="
                                        summaries.profit_or_loss_total_amount <
                                        0
                                    "
                                    class="w-100 text-capitalize text-center align-self-sm-start text-danger"
                                >
                                    {{ $t("summary.totalAmountLoss") }}
                                </h4>
                                <p>
                                    <ICountUp
                                        :delay="delay"
                                        :endVal="endValProfitLoss"
                                        :options="options"
                                        @ready="onReady2"
                                    />

                                    <!-- {{ summaries.profit_or_loss_total_amount }} -->
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingComponentVue from "../animations/LoadingComponent";

import { BASE_URL } from "@/utils/constants";
import { PROFIT_OR_LOSS } from "@/utils/api";
import axios from "axios";
import store from "@/store";

import ICountUp from "vue-countup-v2";
import { format, parse } from "date-fns";
export default {
    name: "ProfitSummary",
    components: {
        LoadingComponentVue,
        ICountUp,
    },

    data() {
        return {
            timeRange: "",
            from: null,
            to: null,
            fromTo: true,

            lang: localStorage.getItem("lang"),

            labelPosition: "on-border",
            showKeepToAdd: false,

            isLoading: false,
            summaries: {},
            // charts
            // count
            delay: 3000,
            endVal: 120500,
            endValProfitLoss: 120500,
            endValTotalRevenues: 120500,
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ",",
                decimal: ".",
                prefix: "",
                suffix: "",
            },
        };
    },
    computed: {
        formattedFrom: {
            get() {
                if (!this.from) {
                    return null;
                }
                // Get the date string from formData and parse it to Date
                return parse(this.from, "yyyy-MM-dd", new Date());
            },
            set(newValue) {
                // Format the Date object as a string and update formData
                this.from = format(newValue, "yyyy-MM-dd");
            },
        },
        formattedTo: {
            get() {
                if (!this.to) {
                    return null;
                }
                // Get the date string from formData and parse it to Date
                return parse(this.to, "yyyy-MM-dd", new Date());
            },
            set(newValue) {
                // Format the Date object as a string and update formData
                this.to = format(newValue, "yyyy-MM-dd");
            },
        },
    },

    async mounted() {
        await this.getProfitOrLoss();
    },

    methods: {
        resetDataFromTo() {
            this.from = "";
            this.to = "";
        },
        async getProfitOrLoss() {
            this.isLoading = true;
            try {
                const response = await axios.get(
                    `${BASE_URL}${PROFIT_OR_LOSS}`,
                    {
                        headers: {
                            Authorization: `Bearer ${store.getters.getUserToken}`,
                        },
                        params: {
                            from: this.from,
                            to: this.to,
                            time_range: this.timeRange,
                        },
                    }
                );
                if (response.status === 200) {
                    this.summaries = response.data;
                    this.endVal = response.data.total_sum_for_expenses;
                    this.endValProfitLoss =
                        response.data.profit_or_loss_total_amount;
                    this.endValTotalRevenues =
                        response.data.total_sum_for_revenues;

                    this.isLoading = false;

                    // console.log(response.data);
                }
            } catch (error) {
                if (error) {
                    console.log(error);
                    this.isLoading = false;
                }
            }
        },
        onReady: function (instance) {
            const that = this;
            instance.update(that.endVal);
        },
        onReady2: function (instance) {
            const that = this;
            instance.update(that.endValProfitLoss);
        },
        onReady3: function (instance) {
            const that = this;
            instance.update(that.endValTotalRevenues);
        },
    },
};
</script>

<style lang="scss" scoped>
.profit-loos-cards-container {
    padding: 20px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    //& p {
    //    width: 400px;
    //    height: 60px;
    //    border: 1px rgba(204, 204, 204, 0.664) solid;
    //    background-color: #ffffff;
    //    border-radius: 10px;
    //    padding-inline: 15px;
    //    color: #2d2d2d;
    //    box-shadow: 1px 1px 3px 2px #dddddd92;
    //    // color: #fff;
    //    font-size: 1.2rem;
    //    text-align: justify;
    //    display: flex;
    //    justify-content: end;
    //    align-items: center;
    //    font-weight: 800;
    //    &:hover {
    //        background-color: rgb(12, 161, 198);
    //        color: white;
    //        cursor: pointer;
    //    }
    //}
}
.profit-loss-paragraph {
    width: 150px;
    height: 200px;
    border: 1px rgba(237, 237, 237, 0.664) solid;
    background-color: #ffffff;
    border-radius: 10px;
    padding-inline: 15px;
    color: #2d2d2d;
    box-shadow: 1px 1px 3px 2px #dddddd92;
    // color: #fff;
    font-size: 1.2rem;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 800;
    gap: 10px;
    &:hover {
        background-color: rgb(12, 161, 198);
        color: white;
        cursor: pointer;
    }
}
.profit-loss-container-flex {
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 700px) {
    .profit-loss-container-flex {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
.card-for-period {
    background-color: rgba(255, 255, 255, 0.232);
    backdrop-filter: blur(5px);
    padding: 5px 20px;
}
</style>
