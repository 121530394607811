<template>
    <div>
        <!-- @mouseover="openWithHover()" -->
        <aside :style="{ backgroundColor: chosenColor }" class="app-main-aside">
            <div class="app-aside-header-container">
                <!-- aside header -->

                <!-- coloring Aside Start -->
                <div
                    v-if="!openedAside"
                    @click="showColorAside = !showColorAside"
                    class="change-aside-color"
                >
                    <!-- <i class="fa-solid fa-ellipsis-vertical mb-4 ms-0"></i> -->
                    <i class="fa-solid fa-palette mb-4 ms-0"></i>
                </div>
                <div
                    :class="
                        lang == 'en'
                            ? 'show-colors-palets-en'
                            : 'show-colors-palets-ar'
                    "
                    v-if="showColorAside && !openedAside"
                >
                    <div
                        @click="closeAsideBgColors()"
                        class="aside-color-close"
                    >
                        x
                    </div>
                    <div class="mb-1 text-decoration-underline p-2 pt-1 pb-0">
                        <!-- {{ $t("colors") }} -->
                        <i
                            :style="{ color: chosenColor }"
                            class="fa-solid fa-paint-roller fa-flip"
                        ></i>
                    </div>
                    <ul>
                        <li>
                            <button
                                @click="
                                    changeAsideBgColor('aside-bg-color-blue')
                                "
                                class="btn btn-change-color btn-change-color-blue"
                            ></button>
                        </li>
                        <li>
                            <button
                                @click="
                                    changeAsideBgColor('aside-bg-color-blue-2')
                                "
                                class="btn btn-change-color btn-change-color-blue-2"
                            ></button>
                        </li>
                        <li>
                            <button
                                @click="
                                    changeAsideBgColor('aside-bg-color-black')
                                "
                                class="btn btn-change-color btn-change-color-black"
                            ></button>
                        </li>
                        <li>
                            <button
                                @click="
                                    changeAsideBgColor(
                                        'aside-bg-color-dark-black'
                                    )
                                "
                                class="btn btn-change-color btn-change-color-dark-black"
                            ></button>
                        </li>
                        <li>
                            <button
                                @click="
                                    changeAsideBgColor('aside-bg-color-gold')
                                "
                                class="btn btn-change-color btn-change-color-gold"
                            ></button>
                        </li>
                        <li>
                            <button
                                @click="
                                    changeAsideBgColor(
                                        'aside-bg-color-light-blue'
                                    )
                                "
                                class="btn btn-change-color btn-change-color-light-blue"
                            ></button>
                        </li>
                        <!-- 
                        <li>
                            <button
                                @click="
                                    changeAsideBgColor(
                                        'aside-bg-color-gradient-blue'
                                    )
                                "
                                class="btn btn-change-color btn-change-color-gradient-blue"
                            ></button>
                        </li> -->
                        <!-- <li>
                        </li> -->
                        <label
                            for="select-color-input-id"
                            class="chose-color-label"
                            :style="{ color: chosenColor }"
                        >
                            <i class="fa-solid fa-palette fa-spin"></i>
                        </label>
                        <input
                            id="select-color-input-id"
                            class="select-main-color-input"
                            type="color"
                            v-model="selectedColorValue"
                            @input="updateSelectedColor"
                        />
                    </ul>
                </div>
                <!-- coloring Aside End -->
                <router-link to="/" style="background-color: transparent">
                    <img
                        style="scale: 2.2"
                        width="60"
                        src="../../assets/imgs/logo/logo.png"
                        alt=""
                        class="app-aside-logo rounded-circle"
                    />
                </router-link>
                <!-- <router-link class="bg-transparent" to="/">
                        <div v-if="!openedAside" class="app-aside-project-name">
                            {{ $t("projectName") }}
                        </div>
                    </router-link> -->
            </div>
            <!-- Aside content Start -->
            <div class="aside-content-container">
                <!-- contacts collapse Start -->

                <AsideTabComponents
                    :openedAside="openedAside"
                    :asideTabTitle="this.$t('asideTabs.contacts')"
                    :asideTabIconClass="'fa-regular fa-address-book'"
                    :tabChildData="contactsChildData"
                />
            </div>
            <div class="aside-content-container">
                <!-- contacts collapse Start -->
                <AsideTabComponents
                    :openedAside="openedAside"
                    :asideTabTitle="this.$t('asideTabs.units')"
                    :asideTabIconClass="'fa-solid fa-hotel'"
                    :tabChildData="contactsChildDataPlaces"
                />
            </div>
            <div class="aside-content-container">
                <!-- contacts collapse Start -->
                <AsideTabComponents
                    :openedAside="openedAside"
                    :asideTabTitle="this.$t('asideTabs.invoices')"
                    :asideTabIconClass="'fa-solid fa-sack-dollar'"
                    :tabChildData="contactsChildDataExpenses"
                />
            </div>

            <div class="aside-content-container">
                <!-- contacts collapse Start -->
                <AsideTabComponents
                    :openedAside="openedAside"
                    :asideTabTitle="this.$t('cheques.cheques')"
                    :asideTabIconClass="'fa-solid fa-money-check-dollar'"
                    :tabChildData="cheques"
                />
            </div>
        </aside>
    </div>
</template>
<script>
import AsideTabComponents from "@/components/aside-components/AsideTabComponents.vue";
import store from "@/store";
export default {
    name: "AppAside",
    components: {
        AsideTabComponents,
    },
    props: {
        openedAside: {
            type: Boolean,
        },
    },
    computed: {
        chosenColor() {
            // return localStorage.getItem("color");
            return store.getters.getSelectedColor;
        },
    },
    data() {
        return {
            // showColorAside: false,
            showColorAside: false,
            lang: localStorage.getItem("lang") || "en",

            bgColor:
                localStorage.getItem("color") || "var(--aside-bg-color-blue)",
            selectedColorValue: this.$store.state.selectedColor,
            arrowDirectionClosed: true,
            showContactsChild: false,
            contactsIsHovered: false,

            // contacts component data

            asideTabIconClass: "",
            asideTabTitle: "",

            contactsChildData: [
                {
                    id: 1,
                    tabName: this.$t("asideTabs.contactsEmployee"),
                    to: "/contacts/employee",
                },
                {
                    id: 2,
                    tabName: this.$t("asideTabs.contactsOwners"),
                    to: "/contacts/landlords",
                },
                {
                    id: 3,
                    tabName: this.$t("asideTabs.contactsBrokers"),
                    to: "/contacts/brokers",
                },
                {
                    id: 4,
                    tabName: this.$t("asideTabs.contactsTenants"),
                    to: "/contacts/tenants",
                },
            ],
            contactsChildDataPlaces: [
                {
                    id: 1,
                    tabName: this.$t("asideTabs.property"),
                    to: "/housing/properties",
                },
                {
                    id: 2,
                    tabName: this.$t("asideTabs.partition"),
                    to: "/housing/partition",
                },
            ],
            contactsChildDataExpenses: [
                {
                    id: 1,
                    tabName: this.$t("asideTabs.expenses"),
                    to: "/invoices/expenses/landlords",
                },
                {
                    id: 2,
                    tabName: this.$t("asideTabs.revenue"),
                    to: "/invoices/revenues",
                },
                {
                    id: 3,
                    tabName: this.$t("asideTabs.revenueMonthlyRenew"),
                    to: "/invoices/revenues-monthly-renew",
                },
            ],
            cheques: [
                {
                    id: 1,
                    tabName: this.$t("cheques.propertyCheques"),
                    to: "/cheques/property",
                },
                {
                    id: 2,
                    tabName: this.$t("cheques.tenantCheques"),
                    to: "/cheques/tenant",
                },
            ],
        };
    },
    created() {},
    methods: {
        // change Color from input
        updateSelectedColor() {
            store.commit("SET_SELECTED_COLOR", this.selectedColorValue);
            localStorage.setItem("color", this.selectedColorValue);
        },
        changeAsideBgColor(colorVariable) {
            this.bgColor = `var(--${colorVariable})`;
            localStorage.setItem("color", this.bgColor);
            store.commit("SET_SELECTED_COLOR", `var(--${colorVariable})`);
            // window.location.reload();
        },
        closeAsideBgColors() {
            this.showColorAside = false;
        },
        openWithHover() {
            this.$emit("openWithHover");
        },
    },
};
</script>

<style lang="scss">
.app-main-aside {
    min-height: 100vh;
    height: 100%;
    color: white;
    text-align: center;
    padding: 20px;
    background-color: #97855c;
    background-color: var(--aside-bg-color-black);
    background-color: var(--aside-bg-color-blue-2);
    background-color: var(--aside-bg-color-gold);
    background-color: var(--aside-bg-color-blue);
}
//  mobiles screen
@media screen and (max-width: 900px) {
    .app-main-aside {
        display: none;
    }
}
// aside header start
// ======================
.app-aside-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    border-bottom: 1px solid gray;
    padding: 0px 10px 10px 0px;
    margin-bottom: 10px;
    position: relative;
}
// Colors palets Start ====
.change-aside-color {
    // width: 5%;
    // cursor: pointer;
    // padding: 10px;
    // background-color: white;
    // border-radius: 50%;
    // width: 30px;
    // height: 30px;
    // display: flex;
    // border: 4px solid white;

    cursor: pointer;
    padding: 3px;
    & i {
        border: 2px solid white;
        border-radius: 50%;
        padding: 5px;
        font-size: 11pt;
        color: white;
    }
}
.show-colors-palets-en {
    background-color: rgb(236 236 236 / 29%);
    backdrop-filter: blur(5px);
    font-size: 10pt;
    position: absolute;
    top: -20px;
    left: calc(var(--aside-open-width) - 20px);
    z-index: 999;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: 18%;
    border-radius: 5px;
    padding: 19px 5px;
}
.show-colors-palets-ar {
    background-color: rgb(236 236 236 / 29%);
    backdrop-filter: blur(5px);
    font-size: 10pt;
    position: absolute;
    top: -20px;
    right: calc(var(--aside-open-width) - 20px);
    z-index: 999;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: 18%;
    border-radius: 5px;
    padding: 19px 5px;
}
.btn-change-color {
    width: 20px;
    height: 20px;
    border: 1px solid white;
    margin-bottom: 5px;
}
.btn-change-color-blue {
    background-color: var(--aside-bg-color-blue) !important;
}
.btn-change-color-blue-2 {
    background: var(--aside-bg-color-blue-2) !important;
}
.btn-change-color-black {
    background: var(--aside-bg-color-black) !important;
}
.btn-change-color-dark-black {
    background: var(--aside-bg-color-dark-black) !important;
}
.btn-change-color-gold {
    background: var(--aside-bg-color-gold) !important;
}
.btn-change-color-light-blue {
    background: var(--aside-bg-color-light-blue) !important;
}
// .btn-change-color-gradient-blue {
//     background: rgb(0, 188, 213);
//     background: radial-gradient(
//         circle,
//         rgba(0, 188, 213, 1) 14%,
//         rgba(181, 232, 229, 1) 65%
//     );
// }
.chose-color-label {
    // color: white;
    font-size: 15pt;
    display: block;
    margin-bottom: 1px;
    border-top: 1px solid gray;
}
.select-main-color-input {
    outline: none;
    border: gray 1px solid;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
}

.aside-color-close {
    width: 16px;
    height: 16px;
    padding: 5px;
    margin-bottom: 0px;
    font-size: 8pt;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(218, 6, 6);
    border-radius: 50%;
    margin: 0 auto;
    &:hover {
        cursor: pointer;
        background-color: rgb(180, 0, 0);
    }
}
// Colors palets End ====

.app-aside-project-name {
    font-size: 12pt;
}
// aside header End
// ======================

// ======== content End
</style>
