<template>
    <!-- @mouseover="closeWithHover()" -->
    <div
        class="home-page"
        :style="computedChangePhotoModel ? 'max-height:20vh' : ''"
    >
        <LoadingComponentVue :isLoading="isLoading" />
        <div
            v-if="
                this.$route.name === 'HomeView' ||
                this.$route.name === 'ContactsParentComponent'
            "
        >
            <h1 v-if="lang == 'en'" class="text-center pb-3">! Welcome Back</h1>
            <h1 v-if="lang == 'ar'" class="text-center pb-3">مرحباً !</h1>
            <hr />
            <!-- style="padding-inline: 25px" -->
            <!-- v-if="
                    this.$store.getters.getUserType == 'SUPERUSER' ||
                    this.$store.getters.getUserType == 'OWNER'
                " -->
            <div>
                <div class="container-fluid">
                    <div
                        :style="{ backgroundColor: chosenColor }"
                        class="row my-5 shadow w-100 p-5"
                        :class="
                            lang == 'ar'
                                ? 'profit-loss-container'
                                : 'profit-loss-container-en'
                        "
                    >
                        <TreasurySummary />
                    </div>
                    <div class="bg-light pt-3">
                        <h2
                            class="p-4 border-bottom border-2 w-50 shadow m-2 rounded-2 bg-info text-white text-center mx-auto"
                        >
                            الربح propfets
                        </h2>
                        <ProfitSummary />
                        <hr />
                    </div>
                </div>
                <section class="p-1">
                    <div
                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        "
                    >
                        <!-- class="col-xxl-6 col-lg-6 col-md-6" -->
                        <ExpensesSummaryAll style="width: 45%" />
                        <RevenuesSummaryAll style="width: 45%" />
                    </div>
                    <div class="">
                        <PropertyExpensesSummary class="my-5 mx-auto" />
                    </div>
                </section>
                <!-- revenues By Month Or Period Start -->
                <section class="shadow">
                    <div
                        :class="
                            lang == 'ar'
                                ? 'revenues-by-cards-container'
                                : 'revenues-by-cards-container-en'
                        "
                    >
                        <!-- card-for-period -->
                        <router-link
                            v-if="
                                this.$store.getters.getUserType ==
                                    'SUPERUSER' ||
                                this.$store.getters.getUserType == 'OWNER' ||
                                this.$store.getters.getUserType ==
                                    'ACCOUNTANT_MONTH'
                            "
                            to="/invoices/monthly/revenues"
                            class=""
                        >
                            <div
                                class="card card-for-month"
                                style="
                                    width: 220px;
                                    max-height: 300px;
                                    min-height: 300px;
                                "
                            >
                                <div class="">
                                    <div>
                                        <h3
                                            class="text-center p-3 bg-success text-white shadow"
                                        >
                                            معاملات شهرية
                                        </h3>
                                    </div>
                                    <div
                                        class="card-body d-flex justify-content-center align-items-center"
                                    >
                                        <img
                                            width="250px"
                                            src="@/assets/imgs/home/rent-monthly.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </router-link>

                        <!-- card-for-period -->
                        <router-link to="/invoices/periodic/revenues" class="">
                            <!-- card -->
                            <div
                                v-if="
                                    this.$store.getters.getUserType ==
                                        'SUPERUSER' ||
                                    this.$store.getters.getUserType ==
                                        'OWNER' ||
                                    this.$store.getters.getUserType ==
                                        'ACCOUNTANT_PERIOD'
                                "
                                class="card card-for-period"
                                style="
                                    width: 220px;
                                    max-height: 300px;
                                    min-height: 300px;
                                "
                            >
                                <div class="">
                                    <div class="">
                                        <h3
                                            class="text-center p-3 bg-info text-white shadow"
                                        >
                                            معاملات فترة
                                        </h3>
                                    </div>
                                    <div
                                        class="card-body d-flex justify-content-center align-items-center"
                                    >
                                        <img
                                            width="250px"
                                            src="../assets/imgs/home/rent-yearly.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <!-- card-for-all -->
                        <router-link to="/invoices/revenues" class="">
                            <!-- card -->
                            <div
                                class="card card-for-period"
                                style="
                                    width: 220px;
                                    max-height: 300px;
                                    min-height: 300px;
                                "
                            >
                                <div class="">
                                    <div class="">
                                        <h3
                                            class="text-center p-3 bg-secondary text-white shadow"
                                        >
                                            جميع الايرادات
                                        </h3>
                                    </div>
                                    <div
                                        class="card-body d-flex justify-content-center align-items-center"
                                    >
                                        <img
                                            width="250px"
                                            src="../assets/imgs/home/revenues.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </section>
                <!-- revenues By Month Or Period End -->
            </div>

            <!-- ================ -->

            <div v-show="this.$route.name === 'ContactsParentComponent'">
                <h2 class="text-center">
                    {{ $t("asideTabs.selectContact") }}
                </h2>
                <p class="text-center">
                    <router-link
                        class="text-decoration-underline px-2"
                        to="/contacts/employee"
                    >
                        {{ $t("asideTabs.contactsEmployee") }} </router-link
                    >|
                    <router-link
                        class="text-decoration-underline px-2"
                        to="/contacts/landlords"
                    >
                        {{ $t("asideTabs.contactsOwners") }} </router-link
                    >|
                    <router-link
                        class="text-decoration-underline px-2"
                        to="/contacts/brokers"
                    >
                        {{ $t("asideTabs.contactsBrokers") }}
                    </router-link>
                    |
                    <router-link
                        class="text-decoration-underline px-2"
                        to="/contacts/tenants"
                    >
                        {{ $t("asideTabs.contactsTenants") }}
                    </router-link>
                </p>
            </div>
            <div v-show="this.$route.name === 'ContactsParentComponent'">
                <h2 class="text-center">{{ $t("asideTabs.selectUnit") }}</h2>
                <p class="text-center">
                    <router-link
                        class="text-decoration-underline px-2"
                        to="/housing/properties"
                    >
                        {{ $t("asideTabs.property") }} </router-link
                    >|
                    <router-link
                        class="text-decoration-underline px-2"
                        to="/housing/partition"
                    >
                        {{ $t("asideTabs.partition") }}
                    </router-link>
                </p>
            </div>

            <hr />

            <!-- fast links -->
            <div class="container shadow p-6 my-6 rounded-3">
                <div class="row w-75 mx-auto">
                    <h2 class="text-secondary text-decoration-underline">
                        روابط سريعة
                    </h2>
                    <router-link
                        to="/housing/properties"
                        class="btn btn-info mb-4 text-white mx-1"
                    >
                        جدول العقارات
                    </router-link>
                    <router-link
                        to="/housing/partition"
                        class="btn btn-warning mb-4 text-white mx-1"
                    >
                        جدول الوحدات المؤجرة
                    </router-link>
                    <router-link
                        to="/invoices/revenues"
                        class="btn btn-danger mb-4 text-white mx-1"
                    >
                        جدول الايرادات
                    </router-link>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
import LoadingComponentVue from "@/components/animations/LoadingComponent.vue";
// import CalculatorComponent from "@/components/global/CalculatorComponent.vue";
// import ScrollAnimation from "@/components/global/ScrollAnimation.vue";
// expenses
import ExpensesSummaryAll from "@/components/summary/ExpensesSummaryAll.vue";
import PropertyExpensesSummary from "@/components/summary/PropertyExpensesSummary.vue";
import ProfitSummary from "@/components/summary/ProfitSummary.vue";
import TreasurySummary from "@/components/summary/TreasurySummary.vue";

// revenues
import RevenuesSummaryAll from "@/components/summary/RevenuesSummaryAll.vue";
import { BASE_URL } from "@/utils/constants";
import { CHECK_AND_RESET_RENEWAL_STATUS } from "@/utils/api";
import axios from "axios";
// import { PROFIT_OR_LOSS } from "@/utils/api";

export default {
    name: "HomeView",
    components: {
        LoadingComponentVue,
        // ScrollAnimation,
        // CalculatorComponent,
        ExpensesSummaryAll,
        PropertyExpensesSummary,
        RevenuesSummaryAll,
        ProfitSummary,
        TreasurySummary,
    },
    created() {
        this.baseUrl = BASE_URL;
    },
    async mounted() {
        await this.checkMonthlyRenewal();
    },
    data() {
        return {
            isLoading: false,
            lang: localStorage.getItem("lang"),
            routName: this.$route.path,
            showCalculator: false,
        };
    },
    methods: {
        closeWithHover() {
            this.$emit("closeWithHover");
        },
        // ========== check monthly renewal
        async checkMonthlyRenewal() {
            this.isLoading = true;
            try {
                const userToken = this.$store.getters.getUserToken;
                const response = await axios.patch(
                    `${BASE_URL}${CHECK_AND_RESET_RENEWAL_STATUS}`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                );
                if (response.status === 200 || response.status === 204) {
                    console.log(
                        "monthly renewal has been lunched",
                        response.data.message
                    );
                    this.isLoading = false;
                }
            } catch (error) {
                if (error) {
                    console.error(error.response.data.detail);
                    this.isLoading = false;
                }
            }
        },
    },
    computed: {
        computedChangePhotoModel() {
            return this.$store.getters.getChangePhotoModel;
        },
        chosenColor() {
            // return localStorage.getItem("color");
            return this.$store.getters.getSelectedColor;
        },
    },
};
</script>
<style lang="scss">
.home-page {
    min-height: var(--min-height-page);
    height: auto;
    overflow: hidden;
}

// ======================= table start =================================
.table-container {
    max-height: 400px; /* Set a maximum height for the table container */
    overflow-y: auto; /* Enable vertical scrolling for the table */
    height: 200vh;
}
.table-thead {
    position: sticky;
    top: 0;
    z-index: 1;
}
.table-actions-container {
    display: flex;
    justify-content: start;
    gap: 15px;
    & i {
        font-size: 14pt;
        &:hover {
            cursor: pointer;
        }
    }
    & i.action-edit {
        color: #fdc51c;
        &:hover {
            color: rgb(219, 173, 35);
        }
    }
    & i.action-del {
        color: rgb(239, 42, 42);
        &:hover {
            color: rgb(202, 22, 22);
        }
    }
}
// ======================= table end =================================

.revenues-by-cards-container {
    background-color: #f3f3f3a5;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    padding: 20px;
    background-image: url("../assets/imgs/home/5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // border-bottom-right-radius: 200px 200px;
    // border-top-right-radius: 200px 200px;
    border-bottom-left-radius: 200px 200px;
    border-top-left-radius: 200px 200px;
}
.revenues-by-cards-container-en {
    background-color: #f3f3f3a5;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    padding: 20px;
    background-image: url("../assets/imgs/home/5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom-right-radius: 200px 200px;
    border-top-right-radius: 200px 200px;
    // border-bottom-left-radius: 200px 200px;
    // border-top-left-radius: 200px 200px;
}
.card-for-period,
.card-for-month {
    &:hover {
        transform: translateX(20px);
        transition: all 0.5s ease-in-out;
        background-color: #ebebeb;
    }
}
.profit-loss-container {
    background-image: url("../assets/imgs/home/5.jpg");
    // opacity: 0.8;
    background-color: #2e2e2eea;
    // background: rgb(58, 190, 210);
    // background: radial-gradient(
    //     circle,
    //     rgba(58, 190, 210, 0.761204481792717) 4%,
    //     rgba(255, 255, 255, 1) 42%
    // );
    background-repeat: no-repeat;
    background-size: auto;
    background-size: 100%;
    background-position: center;
    // border-bottom-right-radius: 200px 200px;
    // border-top-right-radius: 200px 200px;
    border-bottom-left-radius: 200px 200px;
    border-top-left-radius: 200px 200px;
}
.profit-loss-container-en {
    background-image: url("../assets/imgs/home/5.jpg");
    opacity: 0.8;
    background-color: #2e2e2eea;
    // background: rgb(58, 190, 210);
    // background: radial-gradient(
    //     circle,
    //     rgba(58, 190, 210, 0.761204481792717) 4%,
    //     rgba(255, 255, 255, 1) 42%
    // );
    background-repeat: no-repeat;
    background-size: auto;
    background-size: 100%;
    background-position: center;
    border-bottom-right-radius: 200px 200px;
    border-top-right-radius: 200px 200px;
    // border-bottom-left-radius: 200px 200px;
    // border-top-left-radius: 200px 200px;
}
</style>
