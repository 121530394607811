<template>
    <div id="app">
        <div
            v-if="!this.$store.getters.getIsLogin"
            class="login-page-container"
        >
            <UserLoginComponent />
        </div>
        <div
            v-else
            :style="pageDirection == 'rtl' ? 'direction:rtl' : 'direction:ltr'"
            class="app-main-layout-responsive"
            :class="
                openedAside
                    ? 'app-main-layout-opened'
                    : 'app-main-layout-closed'
            "
        >
            <SystemIntro
                v-if="showSystemIntro && !existShowSystemIntro"
                class="system-intro-component"
            />
            <!-- <div v-if="openMobileAside" class="app-mobile-aside">
            </div> -->
            <AppMobileAside
                :openMobileAsideProp="openMobileAside"
                @toggleMobileAside="toggleMobileAside()"
                v-if="openMobileAside"
                class="app-mobile-aside"
            />

            <AppAside
                :openedAside="openedAside"
                @openWithHover="openWithHover()"
            />

            <!-- AppNotifications Start -->
            <AppNotifications
                :style="{ backgroundColor: chosenColor }"
                @closeNotifications="closeNotifications"
                v-if="showNotifications"
                class="app-notification-aside"
            />
            <!-- AppNotifications End -->
            <div>
                <AppHeader
                    @toggleAsideApp="toggleAsideApp()"
                    :openedAside="openedAside"
                    @toggleMobileAside="toggleMobileAside()"
                    @notificationsNow="notificationsNow()"
                />

                <router-view @closeWithHover="closeWithHover()"></router-view>

                <AppFooter />
            </div>
        </div>
    </div>
</template>

<script>
import SystemIntro from "@/components/global/SystemIntro.vue";
import UserLoginComponent from "@/components/user-components/UserLoginComponent.vue";
import AppAside from "@/components/main-app/AppAside.vue";
import AppMobileAside from "@/components/main-app/AppMobileAside.vue";

import AppNotifications from "@/components/notifications/AppNotifications.vue";

import AppHeader from "@/components/main-app/AppHeader.vue";
import AppFooter from "@/components/main-app/AppFooter";

// import store from "@/store";
export default {
    name: "App",
    components: {
        UserLoginComponent,
        SystemIntro,
        AppAside,
        AppMobileAside,
        AppNotifications,
        AppHeader,
        AppFooter,
    },
    data() {
        return {
            pageDirection: "",
            openedAside: true,
            // mobile
            openMobileAside: false,
            showNotifications: false,
            showSystemIntro: true,
            // existShowSystemIntro: sessionStorage.getItem(
            //     "existShowSystemIntro"
            // ),
            existShowSystemIntro:
                false || sessionStorage.getItem("existShowSystemIntro"),
        };
    },
    computed: {
        chosenColor() {
            // return localStorage.getItem("color");
            return this.$store.getters.getSelectedColor;
        },
        existShowSystemIntroComp() {
            return sessionStorage.getItem("existShowSystemIntro");
        },
    },
    beforeCreate() {
        this.showSystemIntro = true;
        if (sessionStorage.getItem("existShowSystemIntro")) {
            sessionStorage.setItem("existShowSystemIntro", false);
        }
        setTimeout(() => {
            this.showSystemIntro = false;
            this.existShowSystemIntro = true;
            if (this.$store.getters.getIsLogin) {
                sessionStorage.setItem(
                    "existShowSystemIntro",
                    this.existShowSystemIntro
                );
            } else {
                sessionStorage.removeItem("existShowSystemIntro");
            }
        }, 4500);
    },
    created() {
        localStorage.setItem("color", this.$store.state.colors.defaultColor);
        localStorage.setItem("lang", this.$store.state.lang.lang);
        if (localStorage.getItem("lang") == "en") {
            this.pageDirection = "ltr";
        } else {
            this.pageDirection = "rtl";
        }
    },
    async mounted() {},
    methods: {
        // =============
        toggleAsideApp() {
            this.openedAside = !this.openedAside;
        },
        openWithHover() {
            this.openedAside = false;
        },
        closeWithHover() {
            this.openedAside = true;
        },
        toggleMobileAside() {
            this.openMobileAside = !this.openMobileAside;
            // console.log("this.openMobileAside", this.openMobileAside);
        },
        // notifications
        notificationsNow() {
            this.showNotifications = !this.showNotifications;
        },
        closeNotifications() {
            this.showNotifications = false;
        },
    },
};
</script>

<style lang="scss">
#app {
}
// open - close aside ======
.app-main-layout-opened {
    display: grid;
    grid-template-columns: var(--app-layout-columns-close);
    grid-template-rows: var(--app-layout-rows);
}
.app-main-layout-closed {
    display: grid;
    grid-template-columns: var(--app-layout-columns-open);
    grid-template-rows: var(--app-layout-rows);
}
// mobile screen
@media screen and (max-width: 900px) {
    .app-main-layout-responsive {
        grid-template-columns: var(--app-responsive-columns);
        grid-template-rows: var(--app-responsive-rows);
    }
}
@media screen and (min-width: 900px) {
    .app-mobile-aside {
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .app-mobile-aside {
        display: none;
    }
}
.app-notification-aside {
    position: fixed;
    left: 0;
    z-index: 999;
    width: 80vw;
    height: 100vh;
    background-color: rgba(87, 87, 87, 0.94);
    backdrop-filter: blur(2px);
}
// open - close aside end ======
.router-link-exact-active {
    color: white;
}
.router-link-active a {
    text-decoration: none;
}
// ================
// .app-page-content-open-sidebar {
// display: grid;
// grid-template-columns: 200px 1fr 80px;
// grid-template-rows: 1fr;
// }
.login-page-container {
    // position: fixed;
    width: 100%;
    // top: 0;
    height: 100vh;
    background: white;
}
.system-intro-component {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 9999999;
}
</style>
