import Vue from "vue";
import App from "./views/app-vue/App.vue";
import router from "./router";
import store from "./store/index";
// import { BASE_URL } from "@/api/constants";
// import axios from "axios";
import "axios";

// const lang = localStorage.getItem("lang") || "ar-EG";
// axios.defaults.headers["Accept-Language"] = lang;
// axios.defaults.baseURL = BASE_URL;

import "normalize.css";
import "../src/assets/fontawesome-free-6.2.0-web/css/all.css";

import "@fortawesome/fontawesome-free/css/all.css";

/*==================================== BootStrap ==================================== */
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

import "@/assets/fonts/bootstrap-icons.woff";
// BootstrapVue
import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
//==================================== end BootStrap ====================================

// ==================================== VueNotification library =========================
import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr, {
    /* OverWrite Plugin Options if you need */
    // defaultTimeout: 3000,
    // defaultProgressBar: false,
    // defaultProgressBarValue: 0,
    // defaultType: "error",
    // defaultPosition: "toast-bottom-left",
    // defaultCloseOnHover: false,
    // defaultStyle: { "background-color": "red" },
    // defaultClassNames: ["animated", "zoomInUp"],
});
// ================================End VueNotification library ===========================
import "./scss/main.scss";

import "jquery";

import VueI18n from "vue-i18n";
import en from "@/i18n/en.json";
import ar from "@/i18n/ar.json";
Vue.use(VueI18n);
const messages = {
    ar: ar,
    en: en,
};
const i18n = new VueI18n({
    // locale: localStorage.getItem("lang") || "en-Us",
    locale: localStorage.getItem("lang") || store.state.lang.lang,
    messages,
});
// ================================

// Vue.config.productionTip = false;
// ====== validation
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);
// ======================
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
// ========= jwt library ================
import VueJWT from "vuejs-jwt";
import options from "vuejs-jwt";

Vue.use(VueJWT, options);
Vue.use(VueJWT);
/// <reference path="./typings/vuelidate.d.ts" />

// ================================
import VueIntersect from "vue-intersect";
Vue.component("VueIntersect", VueIntersect);

// =========================================
Vue.config.productionTip = false;
Vue.use(Vuelidate);
// ==============================================================
import vClickAway from "vue-clickaway";

Vue.use(vClickAway);
// ================================================
// ========= Buefy ==============

import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Buefy);
// =======================
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

Vue.use(VueMaterial);
// =======================

// =======================

new Vue({
    router,
    store,
    i18n,
    VueToastr,
    Vuelidate,

    render: (h) => h(App),
}).$mount("#app");
