<template>
    <div class="user-login-container">
        <LoadingComponent :isLoading="isLoading" />
        <form @submit.prevent class="user-login-form">
            <span
                ><img
                    width="150"
                    src="../../assets/imgs/logo/logo.jpg"
                    alt="img"
                    class="rounded-circle"
            /></span>
            <!-- userName Start -->
            <h1 class="login-page-title">{{ $t("userLogin.loginHeader") }}</h1>
            <div v-if="$v.userNamOrEmail.$error">
                <span class="error-message">{{
                    $v.userNamOrEmail.$params.isValidInput
                        ? $t("userLogin.emailValidationMessage")
                        : $t("userLogin.userIdRequiredMessage")
                }}</span>
            </div>
            <div class="login-user-id login-user-input-container">
                <!-- <label>{{ $t("userLogin.userId") }}</label> -->
                <label>
                    <i class="fa-regular fa-user fs-3"></i>
                </label>
                <input
                    class="login-input-dir"
                    type="text"
                    v-model="userNamOrEmail"
                />
            </div>

            <!-- userName - End -->

            <!-- Password - Start -->
            <div v-if="$v.password.$error">
                <span class="error-message">{{
                    $v.password.$params.required
                        ? $t("userLogin.userIdRequiredMessage")
                        : ""
                }}</span>
            </div>
            <div
                class="login-Password login-user-input-container password-input"
            >
                <!-- <label>{{ $t("userLogin.password") }}</label> -->
                <label>
                    <i class="fa-solid fa-key fs-3"></i>
                </label>
                <div class="pass-and-icon-container">
                    <input
                        class="login-input-dir"
                        :type="showPass ? 'text' : 'password'"
                        v-model="password"
                    />
                    <span
                        class="show-pass-container"
                        @click="showPass = !showPass"
                    >
                        <i
                            v-if="!showPass"
                            class="fa-regular fa-eye show-pass-icon"
                        ></i>
                        <i
                            v-if="showPass"
                            class="fa-regular fa-eye-slash hide-pass-icon"
                        ></i>
                    </span>
                </div>
            </div>

            <!-- Password - End -->

            <!-- remember - start -->
            <div class="remember-me-input-container">
                <div>
                    <input
                        @change="rememberUserFun()"
                        type="checkbox"
                        id="myCheckbox"
                        name="myCheckbox"
                        v-model="rememberUser"
                    />
                    <label for="myCheckbox">
                        <span>{{ $t("userLogin.rememberMe") }}</span>
                    </label>
                </div>
                <!-- <div class="forget-password">
                    <span>
                        <router-link class="text-info" to="">
                            {{ $t("userLogin.forgetPass") }}</router-link
                        >
                    </span>
                </div> -->
            </div>

            <!-- login btn -->
            <div class="login-btn-container">
                <button
                    @click="login()"
                    class="btn btn-info"
                    :disabled="$v.$invalid"
                >
                    {{ $t("userLogin.login") }}
                </button>
            </div>
            <!-- login btn end -->
            <p
                v-if="
                    errorMsg != '' &&
                    errorMsg != this.$t('userLogin.loginSuccess')
                "
                :class="
                    errorMsg != '' &&
                    errorMsg != this.$t('userLogin.loginSuccess')
                        ? 'login-error-msg'
                        : ''
                "
            >
                * {{ errorMsg }}
            </p>
            <p
                v-if="errorMsg == this.$t('userLogin.loginSuccess')"
                :class="
                    errorMsg == this.$t('userLogin.loginSuccess')
                        ? 'login-success-msg'
                        : ''
                "
            >
                * {{ errorMsg }}
            </p>
        </form>
        <div class="user-login-img-container">
            <img
                width="100%"
                height="100%"
                src="../../assets/imgs/login/login-img.jpeg"
                alt=""
            />
        </div>
    </div>
</template>
<script>
import LoadingComponent from "@/components/animations/LoadingComponent.vue";
// import userLoginModule from "@/store/user-login.js";
// ==== vuelidate ====
import { required } from "vuelidate/lib/validators";
import store from "@/store";

export default {
    name: "UserLoginComponent",
    components: {
        LoadingComponent,
    },
    validations: {
        userNamOrEmail: {
            required,
            isValidInput(value) {
                // Custom validator function to check if it's a valid email or not
                if (!value) return true; // Allow empty input (handled by 'required' validator)
                const emailPattern =
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                return emailPattern.test(value) || value.length >= 5; // Modify the length condition as needed
            },
        },
        password: {
            required,
            // Add other password validation rules if necessary
        },
    },
    data() {
        return {
            userNamOrEmail: "",
            password: "",
            isLoading: false,
            errorMsg: "",
            showPass: false,

            rememberUser: localStorage.getItem("rememberUser") || false,
        };
    },
    methods: {
        rememberUserFun() {
            if (this.rememberUser == true) {
                store.commit("SET_REMEMBER_USER", true);
            } else {
                localStorage.removeItem("rememberUser");
            }
        },
        async login() {
            this.isLoading = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errorMsg = "Please fix validation errors.";

                return;
            }

            try {
                const userData = {
                    identifier: this.userNamOrEmail,
                    password: this.password,
                };

                const response = await this.$store.dispatch("login", userData);

                if (response) {
                    // Check if response is defined
                    if (response.status === 200) {
                        this.isLoading = false;
                        // Reset any previous error message
                        this.errorMsg = this.$t("userLogin.loginSuccess");
                        if (this.$route.name != "HomeView") {
                            this.$router.push({ name: "HomeView" });
                        }
                    } else {
                        // this.errorMsg = `Error: ${JSON.stringify(response)}`;
                        const responseData = response.response.data;
                        this.errorMsg = responseData.detail;
                        this.isLoading = false;

                        this.isLoading = false;
                    }
                } else {
                    // this.errorMsg =
                    //     "An error occurred during login. Response is undefined.";
                    this.errorMsg = "request.request.data";
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                // this.errorMsg = "An error occurred during login.";
                this.errorMsg = "عفوا تعذر الإتصال  !";
                console.error("Login failed:", error.response.data);
            }
        },
    },
};
</script>

<style lang="scss">
.user-login-img-container {
    height: 100vh !important;
    & img {
        height: 100vh !important;
        width: 50vw;
    }
}
.pass-and-icon-container {
    position: relative;
}
.show-pass-container {
    position: absolute;
    top: 5px;
    right: 9px;
}
.login-user-input-container {
    & input::placeholder {
        font-size: 8pt;
        text-align: center;
    }
}
@media screen and (max-width: 600px) {
    .pass-and-icon-container {
        width: 100%;
    }
}
</style>
