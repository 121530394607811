// export const LANG = "en";

const storeLang = localStorage.getItem("lang") || "en";
export const LANG = storeLang;
export const LOGIN = `${LANG}/api/users/login/`; // login
export const CREATE_USER = `${LANG}/api/users/create_user/`; // create User
export const GET_ALL_USERS_ADMINS = `${LANG}/api/users/user_list/`; // User list All users admins
export const USER_ROLE_DIALOG = `${LANG}/api/users/user_role_dialog/`; // user role dialog
export const USER_GENDER_DIALOG = `${LANG}/api/users/user_gender_dialog/`; // user role dialog
export const USER_PROFILE = `${LANG}/api/users/user_retrieve/`; // user Profile
export const CHANGE_PROFILE_PHOTO = `${LANG}/api/users/upload_photo/`; // upload_photo

export const USER_UPDATE_BY_ADMIN = `${LANG}/api/users/user_update/`; //  User Update By Admin need user_id
// edit
export const EMPLOYEE_RETRIEVE = `${LANG}/api/users/user_retrieve/`; // user retrieve
export const EDIT_EMPLOYEE = `${LANG}/api/users/user_update/`; // user Profile
export const DELETE_TEMP_EMPLOYEE = `${LANG}/api/users/user_temp_delete/`; // Delete temp User

//#region  // LandLords Module
export const LAND_LORD_CREATE = `${LANG}/api/contacts/landlord_create/`; // create Landlords
export const LAND_LORD_LIST = `${LANG}/api/contacts/landlord_list/`; // get all Landlords
// edit
export const LANDLORD_RETRIEVE = `${LANG}/api/contacts/landlord_retrieve/`; // landlord retrieve
export const LANDLORD_UPDATE = `${LANG}/api/contacts/landlord_update/`; // landlord update
export const DELETE_TEMP_LANDLORD = `${LANG}/api/contacts/landlord_temp_delete/`; // landlord temp delete
//#endregion  // LandLords Module
// ================

//#region // Brokers Module
export const BROKER_CREATE = `${LANG}/api/contacts/broker_create/`; // create Brokers
export const BROKER_LIST = `${LANG}/api/contacts/broker_list/`; // get all Brokers
export const DELETE_TEMP_BROKER = `${LANG}/api/contacts/broker_temp_delete/`; // Broker temp delete
//#endregion // Brokers Module
// ================

//#region // Tenant Module
export const TENANT_CREATE = `${LANG}/api/contacts/tenant_create/`; // create Tenant
export const TENANT_LIST = `${LANG}/api/contacts/tenant_list/`; // get all Tenants
// edit
export const TENANT_RETRIEVE = `${LANG}/api/contacts/tenant_retrieve/`; // tenant retrieve
export const TENANT_UPDATE = `${LANG}/api/contacts/tenant_update/`; // Tenant update
export const DELETE_TEMP_TENANT = `${LANG}/api/contacts/tenant_temp_delete/`; // /tenant_temp_delete/
//#endregion // Tenant Module
// ================

//#region // Property Module
export const CREATE_DIALOG = `${LANG}/api/properties/property_create/`; // Create properties
export const PROPERTY_TYPE_DIALOG = `${LANG}/api/properties/property_type_dialog/`; // properties Type Dialog
export const PROPERTY_LUXURY_DIALOG = `${LANG}/api/properties/property_luxury_dialog/`; // properties Type Dialog
export const LANDLORD_DIALOG = `${LANG}/api/contacts/landlord_dialog/`; // properties landlord Dialog
export const BROKER_DIALOG = `${LANG}/api/contacts/broker_dialog/`; // properties Broker Dialog
export const PROPERTY_LIST = `${LANG}/api/properties/property_list/`; // properties List
export const PROPERTY_RETRIEVE = `${LANG}/api/properties/property_retrieve/`; // properties retrieve
//       ===============   == Edit & delete ==  Property Module ===========
export const PROPERTY_UPDATE = `${LANG}/api/properties/property_update/`; // Property Update
export const DELETE_TEMP_PROPERTY = `${LANG}/api/properties/property_temp_delete/`; // property temp delete
//#endregion // Property Module
// ================

//#region // Partition Module
export const PROPERTY_DIALOG = `${LANG}/api/properties/property_dialog/`; // properties  Dialog
export const PARTITION_CREATE = `${LANG}/api/properties/partition_create/`; // Add partition  Create
export const PARTITION_LIST = `${LANG}/api/properties/partition_list/`; // partition List
export const PARTITION_RETRIEVE = `${LANG}/api/properties/partition_retrieve/`; // Partition retrieve
//       ===============   == Edit & delete ==  Partition Module ===========
export const PARTITION_UPDATE = `${LANG}/api/properties/partition_update/`; // Partition Update
export const DELETE_TEMP_PARTITION = `${LANG}/api/properties/partition_temp_delete/`; // partition temp delete
export const CHANGE_RENT_STATUS = `${LANG}/api/properties/partition_change_status/`; // partition change status

//end#region // Partition Module
// ================

//#region //  invoices المصروفات
// for Partition
export const INVOICES_LANDLORD_CREATE = `${LANG}/api/invoices/payment_to_landlord_create/`; // Expense Create ToLandlord
export const INVOICES_FOR_PROPERTY_CREATE = `${LANG}/api/invoices/expense_for_property_create/`; // Expense For Property Create
export const INVOICES_FOR_PARTITION_CREATE = `${LANG}/api/invoices/expense_for_property_create/`; // Expenses For Partition Create
export const EXPENSES_TYPE_DIALOG = `${LANG}/api/invoices/payment_types_dialog/`; // payment type Dialog
export const PARTITION_EXPENSES_DELETE = `${LANG}/api/invoices/expense_for_partition_delete/`; // delete expense for partition

// == to property
export const EXPENSES_PROPERTY_TYPE_DIALOG = `${LANG}/api/invoices/expense_types_dialog/`; // property expenses Type Dialog
export const EXPENSES_PROPERTY_CREATE = `${LANG}/api/invoices/expense_for_property_create/`; // property expenses Create
export const EXPENSES_PARTITION_CREATE = `${LANG}/api/invoices/expense_for_partition_create/`; // Partition expenses Create
export const PROPERTY_EXPENSES_DELETE = `${LANG}/api/invoices/expense_for_property_delete/`; // delete expense for Property

// get Expenses methods
export const ALL_EXPENSES_FOR_LANDLORD = `${LANG}/api/invoices/payment_to_landlord_list/`; // All Expenses for Landlord
export const ALL_EXPENSES_FOR_PROPERTY = `${LANG}/api/invoices/all_expenses_for_property/`; // All Expenses for Property
export const ALL_EXPENSES_FOR_PARTITION = `${LANG}/api/invoices/all_expenses_for_partition/`; // All Expenses for Partitions
export const DELETE_EXPENSE_TO_LAND_LORD = `${LANG}/api/invoices/payment_to_landlord_delete/`; // All Expenses for Partitions

//#endregion //  invoices المصروفات
// ================

//#region  // Revenue الايرادات
export const REVENUE_PARTITION_CREATE = `${LANG}/api/invoices/revenue_for_partition_create/`; // Partition Revenue Create
export const PARTITIONS_BY_PROPERTY = `${LANG}/api/properties/partition_by_property_list/`; // Partitions By property || property partitions
export const TENANT_DIALOG = `${LANG}/api/contacts/tenant_dialog/`;
export const REVENUE_PERIODS_DIALOG = `${LANG}/api/invoices/revenue_periods_dialog/`; // RevenuePeriodsDialog
export const REVENUE_LIST = `${LANG}/api/invoices/revenue_list/`; // revenue_list/
export const REVENUE_DELETE = `${LANG}/api/invoices/revenue_delete/`; // revenue delete

export const REVENUE_BY_PROPERTY = `${LANG}/api/invoices/revenue_by_property_list/`; // revenue by property list
export const REVENUE_BY_PARTITION = `${LANG}/api/invoices/revenue_by_partition_list/`; // revenue by partition list
//#endregion

// Cheques

//#region // Cheques
// property Cheques ####
export const PROPERTY_CHEQUE_LIST = `${LANG}/api/invoices/cheques_list/`; //  property cheque list
export const PROPERTY_CHEQUE_CHANGE_STATUS = `${LANG}/api/invoices/cheque_change_status/`; //  Property cheque change status
export const PROPERTY_CHEQUE_RETRIEVE = `${LANG}/api/invoices/cheque_retrieve/`; //  Property  cheque retrieve
export const PROPERTY_CHEQUE_UPDATE = `${LANG}/api/invoices/cheque_update/`; //  Property   cheque update
// tenant cheque ######
export const TENANT_CHEQUE_LIST = `${LANG}/api/invoices/tenant_cheque_list/`; // /tenant_cheque_list/
export const TENANT_CHEQUE_CHANGE_STATUS = `${LANG}/api/invoices/tenant_cheque_change_status/`; //  /tenant cheque change status
export const TENANT_CHEQUE_RETRIEVE = `${LANG}/api/invoices/tenant_cheque_retrieve/`; //  tenant cheque retrieve
export const TENANT_CHEQUE_UPDATE = `${LANG}/api/invoices/tenant_cheque_update/`; // tenant cheque update/

//#endregion // tenant cheque #####

//#region notifications
// notifications
export const NOTIFICATIONS_PROPERTY_LIST = `${LANG}/api/invoices/cheques_notification_list/`; // cheques notification list
export const CHECK_CHEQUES = `${LANG}/api/invoices/check_cheques/`; // /check_cheques/
export const NOTIFICATIONS_TENANT_LIST = `${LANG}/api/invoices/tenant_cheques_notification_list/`; // tenant cheques notification list

export const DELETE_CHEQUE_NOTIFICATION = `${LANG}/api/invoices/nots_cheque_delete/`; // /nots_cheque_delete/
export const DELETE_TENANT_CHEQUE_NOTIFICATION = `${LANG}/api/invoices/tenant_cheque_nots_delete/`; // tenant cheque nots delete
//#endregion notifications

//#region // Summary
// expenses
export const SUMMARY_FOR_ALL_EXPENSES = `${LANG}/api/invoices/summary_for_expenses/`; // summary for expenses
export const SUMMARY_FOR_PROPERTY_EXPENSES = `${LANG}/api/invoices/summary_expenses_for_property/`; // summary expenses for property

// revenues
export const SUMMARY_FOR_ALL_REVENUES = `${LANG}/api/invoices/summary_for_revenues/`; // summary for revenues
export const PROFIT_OR_LOSS = `${LANG}/api/invoices/profit_or_loss/`; // profit or loss

// treasury
export const TREASURY = `${LANG}/api/invoices/treasury/`; // treasury

//#endregion // Summary

//#region // monthly revenues
export const MONTHLY_REVENUES = `${LANG}/api/invoices/revenue_by_month/`; // revenue by month
export const PERIODIC_REVENUES = `${LANG}/api/invoices/revenue_by_period/`; //  revenue by period

export const MONTHLY_REVENUES_RENEW_TABLE = `${LANG}/api/invoices/revenue_by_month_for_occupied/`; //  revenue by month for occupied / renew monthly rent revenue
export const REVENUE_RENEW = `${LANG}/api/invoices/revenue_renew/`; //  revenue renew Post

export const CHECK_AND_RESET_RENEWAL_STATUS = `${LANG}/api/properties/check_and_reset_renewal_status/`; //  check_and_reset_renewal_status
//#endregion // monthly revenues
