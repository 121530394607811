<template>
    <div>
        <div class="contacts-container" v-on-clickaway="closeTabClickaway">
            <div
                v-if="!openedAside"
                @click="toggleCollapse()"
                class="aside-content-text-icon-container"
            >
                <span>
                    <i :class="asideTabIconClass"></i>
                </span>
                <span>{{ asideTabTitle }}</span>
                <!-- arrow open and close -->
                <i
                    v-if="arrowDirectionClosed"
                    class="fa-solid fa-caret-right fs-6"
                ></i>
                <i v-else class="fa-solid fa-caret-down fs-6"></i>
            </div>
            <div
                @click="contactsIsHovered = !contactsIsHovered"
                v-else
                class="aside-content-only-icon-container"
            >
                <i :class="asideTabIconClass"></i>
            </div>
            <!-- v-on-clickaway="closeTabClickaway" -->
            <div
                v-if="showContactsChild && !openedAside"
                class="child-contact-open"
            >
                <!-- contacts child tabs  Start-->
                <ul
                    :class="
                        lang == 'en'
                            ? 'child-contact-ul'
                            : 'child-contact-ul-ar'
                    "
                >
                    <li v-for="childTab in tabChildData" :key="childTab.id">
                        <router-link :to="childTab.to">
                            {{ childTab.tabName }}
                        </router-link>
                    </li>
                </ul>
                <!-- contacts child tabs  Start End-->
            </div>
            <!-- when closed aside  -->
            <div
                v-if="!showContactsChild && openedAside"
                :class="contactsIsHovered ? 'd-block' : 'd-none'"
            >
                <!-- contacts child tabs  Start-->
                <div
                    :class="lang == 'en' ? 'child-contact' : 'child-contact-ar'"
                >
                    <ul
                        :style="{ backgroundColor: chosenColor }"
                        :class="
                            lang == 'en'
                                ? 'child-contact-ul-side-menu'
                                : 'child-contact-ul-side-menu-ar'
                        "
                        v-if="openedAside"
                    >
                        <li v-for="childTab in tabChildData" :key="childTab.id">
                            <router-link :to="childTab.to">
                                {{ childTab.tabName }}
                            </router-link>
                        </li>
                    </ul>
                </div>

                <!-- contacts child tabs  Start End-->
            </div>
            <!-- contacts collapse Start -->
        </div>
    </div>
</template>
<script>
import { directive as onClickaway } from "vue-clickaway";
export default {
    name: "ContactsComponents.",
    directives: {
        onClickaway: onClickaway,
    },
    props: {
        openedAside: {
            type: Boolean,
        },
        asideTabTitle: {
            type: String,
        },
        tabChildData: {
            type: [Array, Object],
        },
        asideTabIconClass: {
            type: String,
        },
    },

    data() {
        return {
            lang: localStorage.getItem("lang"),
            arrowDirectionClosed: true,
            showContactsChild: false,
            contactsIsHovered: false,
        };
    },

    computed: {
        chosenColor() {
            // return localStorage.getItem("color");
            return this.$store.getters.getSelectedColor;
        },
    },
    watch: {
        openedAside(newVal) {
            if (newVal === true) {
                this.closeShowContactsChild();
            }
        },
    },

    methods: {
        closeShowContactsChild() {
            this.showContactsChild = false;
        },
        toggleCollapse() {
            this.arrowDirectionClosed = !this.arrowDirectionClosed;
            this.showContactsChild = !this.showContactsChild;
            // this.contactsIsHovered = !this.contactsIsHovered;
        },
        closeTabClickaway() {
            this.contactsIsHovered = false;
        },
    },
};
</script>
<style lang="scss">
// ======== content start
.aside-content-text-icon-container {
    & span {
        display: inline-block;
        display: inline-block;
        min-width: 94px;
        text-align: start;
    }
}
.aside-content-text-icon-container,
.aside-content-only-icon-container {
    font-size: 12pt;
    height: auto;
    padding: 5px 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
    &:hover {
        cursor: pointer;
    }

    & i {
        font-size: 2rem;
    }
}
.contacts-container {
    position: relative;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

// ==============
.child-contact-ul {
    animation: childMenuAnimation 0.5s ease-in-out;
    width: fit-content;
    margin: 0 7px 0 92px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // border: #97855c 1px solid;
    & li a {
        background-color: rgb(65, 65, 65);
        border-radius: 15px;
        margin-bottom: 8px;
        width: 100%;
        min-width: 155px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 4px 38px;
        color: #ededed !important;
        &:hover {
            background-color: white;
            color: #666 !important;
        }
    }
    & li .router-link-active,
    .router-link-exact-active a {
        background-color: white !important;
        color: #666 !important;
    }
}

.child-contact-ul-ar {
    animation: childMenuAnimationAR 0.5s ease-in-out;
    width: fit-content;
    margin: 0 92px 0 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // border: #97855c 1px solid;
    & li a {
        background-color: rgb(65, 65, 65);
        border-radius: 15px;
        margin-bottom: 8px;
        width: 100%;
        min-width: 155px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 4px 38px;
        color: #ededed !important;
        &:hover {
            background-color: white;
            color: #666 !important;
        }
    }
    & li .router-link-active,
    .router-link-exact-active a {
        background-color: white !important;
        color: #666 !important;
    }
}
// ==============
.child-contact {
    width: 195px;
    height: fit-content;
    background-color: #98989859 !important;
    position: absolute;
    top: 17px;
    left: 47px;
    z-index: 9;
    border-top-right-radius: 27px;
    padding: 5px 0;
    backdrop-filter: blur(2px);
    animation: childMenuAnimation 0.2s ease-in-out;
}
.child-contact-ar {
    width: 195px;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(152, 152, 152, 0.3490196078) !important;
    position: absolute;
    top: 17px;
    right: 47px;
    z-index: 9;
    border-top-left-radius: 27px;
    padding: 5px 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    animation: childMenuAnimationAR 0.1s ease-in-out;
}
// ==============
.child-contact-ul-side-menu {
    width: 143px;
    min-height: 150px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 15px;
    /* position: absolute; */
    top: 9px;
    left: 1px;
    z-index: 999;
    background-color: rgba(24, 24, 24, 0.612);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0px 4px 2px 0px rgba(211, 211, 211, 0.6);
    border: 2px solid #f7f7f7;
    border-left: 0px;
    border-top-right-radius: 50px;
    border-top-left-radius: 0px;
    animation: childMenuAnimation 0.5s ease-in-out;
    & li a {
        display: block;
        background-color: rgb(65, 65, 65);
        border-radius: 15px;
        margin: 6px 0px 0px 0;
        width: 150px;
        animation: childMenuAnimation 0.3s ease-in-out;
        color: #ededed !important;
        padding: 2px 5px;
        &:hover {
            background-color: white;
            color: #666 !important;
            transform: translateX(10px);
            transition: all 0.2s ease-in-out;
        }
    }
    & li .router-link-active,
    .router-link-exact-active a {
        background-color: rgb(25, 11, 11);
        // color: #666 !important;
        transform: translateX(10px);
        transition: all 0.2s ease-in-out;
    }
}
@keyframes childMenuAnimation {
    from {
        opacity: 0;
        transform: translateX(-20px);
        background-color: rgba(245, 245, 245, 0.364);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes childMenuAnimationAR {
    from {
        opacity: 0;
        transform: translateX(20px);
        background-color: rgba(245, 245, 245, 0.364);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
.child-contact-ul-side-menu-ar {
    width: 143px;
    min-height: 150px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 15px;
    /* position: absolute; */
    top: 9px;
    left: 1px;
    z-index: 999;
    background-color: rgba(24, 24, 24, 0.612);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0px 4px 2px 0px rgba(211, 211, 211, 0.6);
    border: 2px solid #f7f7f7;
    border-left: 0px;
    border-top-left-radius: 50px;
    border-top-right-radius: 0px;
    animation: childMenuAnimationAR 0.5s ease-in-out;
    & li a {
        display: block;
        background-color: rgb(65, 65, 65);
        border-radius: 15px;
        margin: 6px 0px 0px 0;
        width: 150px;
        animation: childMenuAnimationAR 0.3s ease-in-out;
        color: #ededed !important;
        padding: 2px 5px;
    }
    & li .router-link-active,
    .router-link-exact-active a {
        background-color: rgb(25, 11, 11);
        // color: #666 !important;
        transform: translateX(-10px);
        transition: all 0.2s ease-in-out;
    }
}

// ============
</style>
