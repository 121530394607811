<template>
    <div
        :style="{ height: height }"
        v-if="isLoading"
        class="loading-page-container"
    >
        <h1 class="Loading-title">
            <img
                class="loading-img"
                src="../../assets/imgs/animations-gif/Loading08.gif"
                alt=""
            />
        </h1>
        <!-- <b-notification :closable="false">
            <b-loading :is-full-page="isFullPage" :can-cancel="true">
                <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin"
                >
                </b-icon>
            </b-loading>
        </b-notification> -->
    </div>
</template>
<script>
export default {
    name: "LoadingComponent",
    props: {
        isLoading: {
            type: Boolean,
        },
        isFullPage: {
            type: Boolean,
            default: true,
        },
        height: {
            type: String,
        },
    },
};
</script>
<style lang="scss">
.loading-page-container {
    min-height: 100vh;
    // height: height;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.473);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;
}
.loading-img {
    // width: 100vw !important;
    // height: 100vh !important;
    // backdrop-filter: blur(31px);
    width: 132px !important;
}
</style>
