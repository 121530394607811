import Vue from "vue";
import Vuex from "vuex";
import UserLogin from "./user-login.js";
import lang from "./lang.js";
import colors from "./colors.js";

// import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        changePhotoModel: false,
    },
    getters: {
        getChangePhotoModel: (state) => {
            return state.changePhotoModel; // return the value
        },
    },

    mutations: {
        setChangePhotoModel(state, payload) {
            state.changePhotoModel = payload;
        },
    },
    actions: {},
    modules: {
        UserLogin,
        lang,
        colors,
    },
});
