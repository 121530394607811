var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-all-expenses-by-property"},[_c('LoadingComponentVue',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{},[_c('div',{staticClass:"card all-expenses-summary-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-top justify-content-between"},[_vm._m(0),_c('div',{staticClass:"flex-fill ms-3"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap"},[_c('div',[_vm._m(1),_c('div',{staticClass:"input-sup-container"},[_c('b-field',{attrs:{"label":_vm.$t('partition.property'),"label-position":_vm.labelPosition,"type":_vm.isValidProperty
                                                ? 'is-success'
                                                : 'is-danger' &&
                                                  !_vm.isSubmitClicked
                                                ? ''
                                                : 'is-danger',"message":_vm.isValidProperty
                                                ? ''
                                                : _vm.$t(
                                                      'addUserValidation.required'
                                                  )}},[_c('b-select',{attrs:{"placeholder":_vm.propertiesDialog.length ===
                                                0
                                                    ? 'loading'
                                                    : 'Select a type',"expanded":"","loading":_vm.propertiesDialog.length ===
                                                0},on:{"input":function($event){return _vm.getPropertyExpensesSummary()}},model:{value:(_vm.formData.property),callback:function ($$v) {_vm.$set(_vm.formData, "property", $$v)},expression:"formData.property"}},[_c('option',{attrs:{"selected":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("select"))+" ")]),_vm._l((_vm.propertiesDialog),function(property,index){return _c('option',{key:index,domProps:{"value":`${property.property_id}`}},[_c('span',[_vm._v(_vm._s(property.title))])])})],2)],1)],1),_c('h4',{staticClass:"fw-semibold mt-1"},[_c('span',{staticClass:"fs-6 mx-1"},[_vm._v(_vm._s(_vm.$t("currency"))+" ")]),_c('span',[_vm._v(_vm._s(_vm.summaries.total_sum))])])]),_c('div',{staticStyle:{"min-height":"40px"},attrs:{"id":"crm-total-revenue"}},[_vm._m(2),(
                                        _vm.summaries.partitions_expenses_total_amount
                                    )?_c('p',[_vm._v(" Partitions : "+_vm._s(_vm.summaries.partitions_expenses_total_amount)+" ")]):_vm._e(),(
                                        _vm.summaries.payment_to_landlords_total_amount
                                    )?_c('p',[_vm._v(" landlords "+_vm._s(_vm.summaries.payment_to_landlords_total_amount)+" ")]):_vm._e(),(
                                        _vm.summaries.partitions_expenses_total_amount
                                    )?_c('p',[_vm._v(" property "+_vm._s(_vm.summaries.partitions_expenses_total_amount)+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-1"},[_c('div',[_c('router-link',{attrs:{"to":"/invoices/expenses/landlords","href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t("viewAll"))),_c('i',{staticClass:"ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block"})])],1),_c('div',{staticClass:"text-end"},[_c('p',{staticClass:"mb-0 text-success fw-semibold"},[_c('router-link',{attrs:{"to":"/add-expenses"}},[_vm._v(" + ")])],1),_c('span',{staticClass:"text-muted op-7 fs-11"})])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"avatar avatar-md avatar-rounded bg-secondary"},[_c('i',{staticClass:"ti ti-wallet fs-16"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"mb-0 d-inline-block"},[_vm._v(" إجمالي مصروفات شقة ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apexcharts-canvas apexchartsl7rj4trk apexcharts-theme-light",staticStyle:{"width":"100px","height":"40px"},attrs:{"id":"apexchartsl7rj4trk"}},[_c('img',{attrs:{"width":"30","src":require("../../assets/imgs/app-header-icons/expenses-icon.png")}})])
}]

export { render, staticRenderFns }